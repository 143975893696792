.user-management-body {
  justify-content: right;
  width: 95%;
  left:25px;
}
.header-primary{
font-size: 20px; 
  width: 90%;
  margin-top:15px;
}
.header-secondary{
font-size: 16px;
margin-top:15px;
}