.order-products {

  .order-number {
    text-align: left;
    float: left;
    clear: none;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .order-status {
    text-align: right;
    float: right;
    clear: none;

    :first-letter {
      text-transform: uppercase
    }
  }

  .no-order-products {
    display: flex;
    width: 100%;
    margin-top: -260px;
    height: 100px;
    text-align: center;
  }
  .consumer-pagination-section{
    bottom: 0px !important;
    margin-top: 6px;
  }

  .no-orders-sidenav {
    display: flex;
    width: 100%;
    margin-top: -300px;
    height: 100px;
    text-align: center;
  }

  .container {
    display: grid;
    /* grid containers */
    grid-template-columns: repeat(3, 1fr);
    /* four columns */
    grid-template-rows: repeat(3, 1fr);
    /* four rows */
    grid-auto-flow: column;
    /* in column direction */
  }

  .column {
    display: contents;
    /* the child elements would be grid items */
    border: 1px solid black;

    .text-value {
      font-weight: 700
    }
  }

  .cell {
    border: 1px solid black;
    padding: 5px;
  }

  .align-checkbox {
    text-align: center;
    margin: auto;
    width: 50%
  }
}

.add-product-global-btns {
  --background: #E8E8E8;
  border-radius: 4px;
  color: black;
  text-transform: capitalize;
  font-size: 15px;
  margin-right: 5px;
}

.orderNav-back-icon {
  max-width: max-content !important;
  margin-top: 6px;
}

.modal-pop-products {
  --width: 400px;
  --border-radius: 10px;
  --height: 90%;
}
@media(min-width:1282px){
  .modal-pop-products {
      --width: 400px;
      --height: 55%;
      --border-radius: 10px;
  }
}
.modal-pop-products-mobile {
  --width: 400px;
  --height: 530px;
  --border-radius: 10px;
}

.ion-chip-sidenav {
  margin-top: 5px;
  right: 12px;
  width: 36px;
  background-color: #eaeaeb0a;
}

.order-submit-mobile {
  float: right;
}

.order-row-property {
  display: flex;
  flex-direction: row;
}

.order-tablet-btns {
  display: flex;
  flex-direction: row;
  float: right;
  margin-top: 5px;
}
@media (min-width: 1282px) {
  .total-expense-content {

    color: #000000;
    width: 100%;
    height: 50px;
    text-align: center;
    font-weight: 600;
    left: 42% !important;
    position: relative;
    top: 20%;
  
    .order-float-right {
      float: right;
      margin-top: 9px;
    }
  }
}
td, th {
  border: 1px solid black;
  text-align: left;
  padding: 8px;
}
.consumer-pagination-section-order-mobile {
  margin: auto;
  height: 50px;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
.order-details-section {
  width: 100%;
}