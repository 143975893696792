 .search-container-div {
     margin-left: 25%;
     margin-right: 25%;
 }
 .search-container-div-mobile {
     margin-left: 10%;
     margin-right: 10%;
 }
 .search-page-desc {
     font-family: "Roboto";
     font-weight: 500;
     font-size: 30px;
     color: #0b0a0a;
     text-align: center;
     margin-top: 35px;
 }
 .search-no-products-span {
     font-family: "Roboto";
     font-weight: 500;
     color: #0b0a0a;
     text-align: center;
 }
 .search-list-ion-items {
     overflow-y: auto;
     height: 325px;
 }
 .pos-search-container-display {
     display: none;
 }
 .pos-main-container {
     display: flex;
 }
 .pos-search-col {
     width: 50%;
     height: 100%;
     margin-top: 20px;
     margin-left: 20px;
     margin-right: 20px;
 } 
 .pos-search-col-desk {
    width: 60%;
    height: 100%;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
} 
 .pos-search-col-sidenav-disabled {
     width: 100%;
     height: 100%; 
 }
 .pos-checkout-col {
     background-color: var(--ion-color-light-shade);
     width: 50%;
     height: calc(100vh - 50px);
 }
 .pos-checkout-col-desk {
    background-color: var(--ion-color-light-shade);
    width: 35% !important;
    height: calc(100vh - 50px);
}
  
 .pos-checkout-col-mobile {
     background-color: var(--ion-color-light-shade);
     height: calc(100vh - 50px);
     width: 100%;
     margin-inline-start: -100%;
 }
 .pos-side-nav-icon {
     vertical-align: middle;
     font-size: 28px;
     margin-top: 20px;
     margin-left: 20px;
     margin-right: 20px;
 }
 .pos-header-text {
    font-size: 22px!important;
    font-weight: bolder!important;
    letter-spacing: .02em;
    margin-top: 0.5em!important;
    padding-bottom: 0;
    padding-left: 0.1em;
    padding-right: 1.1em;
    vertical-align: bottom;
 }
 .pos-search-col-width{
    width: 80%; 
    margin-left: 60px;
 }