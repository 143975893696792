.api-demo-container{
    padding:20px;
    min-height: 100vh;
 }
 
 .containers{
     display: flex;
     flex-direction: row;
     width:100vw;
     margin-bottom:20px;
 }

 .container-card{
    background-color: rgb(99, 128, 467);
    width: 30%;
    height: 59px;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    padding-left: 5px;
 }

 .container-logo{
    height: 49px;
    width: 58px;
    margin-top: 5px;
 }

 .details{
    margin-left: 2px;
    height: 90px;
 }

 .value{
    font-family: roboto;
    font-size: 25px;
    font-weight: bold;
    color: white;
    margin-top: 8px;
    margin-bottom: auto;
 }

 .details-text{
    font-family: roboto;
    font-size: 13px;
    margin-top: 1px;
    color: white;
 }

@media screen and (max-width:768px){
    .containers{
        padding:5px;
        display: flex;
        flex-direction: column;
        width: 90vw;
    }

    .container-card{
        background-color: rgb(99, 128, 467);
        width: 90%;
        height: 59px;
        border-radius: 6px;
        display: flex;
        flex-direction: row;       
    }

   .container-logo{
        height: 49px;
        width: 58px;
        margin-top: 5px;
        margin-right:4px;
     }

}

 .location-container{
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    width:40%;
    height:40px;
    margin-top:50px;
}

@media screen and (max-width:768px){
    .location-container{
        display:flex;
        flex-direction: row;
        justify-content: flex-start;
        width:90%;
        height:40px;
    }
}

 .table-api{
    display: flex;
    margin-top:60px;
    overflow-x:scroll;
    
 }
 .dropdown-location{
     height:20px;
 }
 .grid-1{
    border:0.5px solid #e0d9d9 ;
    margin-left: 20px;
    padding:0px;
   min-width:1000px;
    border-radius: 5px;
}
 @media screen and (max-width:768px){
    .table-api{
        width:90vw;
        overflow-x: scroll;
        margin-left:10px;
    }
    .grid-1{
        border:0.5px solid #e0d9d9 ;
        margin-left: 0px;
        padding:0px;
        min-width:1000px;
        border-radius: 5px;
        margin-right: 20px;
    }
}

.paragraph {
    border: 2px solid blue;
    height: 150px;
    width: 180px;
    overflow: auto;
  }

.header-element{
   border-bottom:0.5px solid #e0d9d9;
  }

.br{
    border-right:0.5px solid #e0d9d9;
    width:120px;
}

.text{
    font-size: 15px;
    color:rgb(14, 13, 13);
    padding-top:0px;
    margin-top: 0px;
    font-weight:800;
    font-family:calibri;
}

.text-1{
    font-size: 15px;
    color:rgb(17, 17, 17);
    padding-top:0px;
    margin-top: 0px;
    font-family:calibri;
}

.content-text{
    font-family:roboto;
    font-size:15px;
    color:#000000;
    font-weight:500;
    margin-left: 10px;
}

.button-container{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right:10px;

}

.button-save{
    background-color: rgb(99, 128, 467);
    width:80px;
    height:30px;
    color:#ffffff;
    font-family:roboto;
    font-size:15px;
    border-width:0px;
    border-radius:13px;
}

.info-box{
    margin-top:80px;
    background-color:#f8f5fa;
    border:2px solid rgb(99, 128, 467);
    border-radius:3px;
    height:100px;
    padding:10px;
    margin-left:20px;
    margin-right:20px;
}

.information{
font-family: roboto;
font-size:18px;
font-weight: bold;
color:#000000;
margin-top:3px;
}