// Colors
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #2F855A;
$white: #fff;
$focus-color: #ff0;

.toggle-switch {
  position: relative;
  margin-right: 10px;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  &-checkbox {
    display: none;
  }
  &-label {
    display: block;
    overflow: hidden;
    cursor: pointer;
    border: 0 solid $label-colour;
    border-radius: 20px;
    margin: 0;
    &:focus {
      outline: none;
      > span {
        box-shadow: 0 0 2px 5px red;
      }
    }
    > span:focus {
      outline: none;
    }
  }
  &-inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
    &:before,
    &:after {
      display: block;
      float: left;
      width: 50%;
      height: 34px;
      padding: 0;
      line-height: 34px;
      font-size: 14px;
      color: white;
      font-weight: bold;
      box-sizing: border-box;
    }
    &:before {
      content: attr(data-yes);
      text-transform: uppercase;
      padding-left: 10px;
      background-color: $toggle-colour;
      color: $white;
    }
  }
  &-disabled {
    background-color: $disabled-colour;
    cursor: not-allowed;
    &:before {
      background-color: $disabled-colour;
      cursor: not-allowed;
    }
  }
  &-inner:after {
    content: attr(data-no);
    text-transform: uppercase;
    padding-right: 10px;
    background-color: $label-colour;
    color: $white;
    text-align: right;
  }
  &-switch {
    display: block;
    width: 24px;
    margin: 5px;
    background: $white;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 40px;
    border: 0 solid $label-colour;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
  }
  &-checkbox:checked + &-label {
    .toggle-switch-inner {
      margin-left: 0;
    }
    .toggle-switch-switch {
      right: 0px;
    }
  }
  &.small-switch {
    width: 40px;
    .toggle-switch-inner {
      &:after,
      &:before {
        content: "";
        height: 20px;
        line-height: 20px;
      }
    }
    .toggle-switch-switch {
      width: 16px;
      right: 20px;
      margin: 2px;
    }
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}
.switch-service-info {
    position: relative;
    height: 1.938em;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    display: inline-block;
    background: #f4f5f8;
    //border: 1px solid #5260ff;
    overflow: hidden;
}
//.switch-service-info:hover {
//    overflow: visible;
//}
.switch__label {
    cursor: pointer;
    transition: color 200ms ease-out;
    -moz-transition: color 200ms ease-out;
    -o-transition: color 200ms ease-out;
    -ms-transition: color 200ms ease-out;
    min-width: 6.000em;
    left: 0;
    z-index: 1;
    font-size: 12px;
    padding: 0em 0.857em;
    z-index: 1;
    position: relative;
    color: #000000;
    text-align: center;
    line-height: 2.2;
    // font-weight: bold;
    font-family: Roboto;
}
input:checked + .switch__label {
    color: white !important;
}
.switch-service-info .toggle-border-box{
    margin-left: auto;
}
.switch__indicator {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97), background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    -moz-transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97), background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    -o-transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97), background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    -ms-transition: transform 600ms cubic-bezier(0.02, 0.94, 0.09, 0.97), background 300ms cubic-bezier(0.17, 0.67, 0.14, 1.03);
    transform: translate3d(0rem, 0, 0);
    -webkit-transform: translate3d(0rem, 0, 0);
    -moz-transform: translate3d(0rem, 0, 0);
    -ms-transform: translate3d(0rem, 0, 0);
    -o-transform: translate3d(0rem, 0, 0);
    z-index: 0;

    font-size: 1rem;
}
.switch-service-info input.custom1:checked ~ .switch__indicator {
    transform: translate3d(0, 0, 0);
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    -moz-border-top-left-radius: 3px;
    -moz-border-bottom-left-radius: 3px;
    -webkit-border-top-left-radius: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    background: var(--ion-color-primary);
    
}
.switch-service-info input.custom2:checked ~ .switch__indicator {
    transform: translate3d(100%, 0, 0);
    -webkit-transform: translate3d(100%, 0, 0);
    -moz-transform: translate3d(100%, 0, 0);
    -ms-transform: translate3d(100%, 0, 0);
    -o-transform: translate3d(100%, 0, 0);
    -moz-border-top-right-radius: 3px;
    -moz-border-bottom-right-radius: 3px;
    -webkit-border-top-right-radius: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    background: var(--ion-color-primary);
}
.clearfix:before{
    display: block;
    content:" ";
    clear: both;
    height: 0;
}
.clearfix:after{
    content: " ";
    clear:both;
}
.toggle-switch-checkbox {
  font-size: 12;
  font-weight: "bold";
}