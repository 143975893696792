.pos-comp-reason-description{
    border: 1px solid #bfbfbf;
    margin-top: auto;
    margin-left: 10px;
    margin-right: 10px;
    --highlight-color-focused: none !important;
    padding-left: 5px !important;
}
 
.pos-purchase-comp-modal{
    --height:340px !important;
}
.pos-comp-label{
    color: gray;
    width: -webkit-fill-available;
    font-size: 12px;
    font-family: Roboto;
    margin-left: 10px;
}


.poc-tablet-complimentary {
    display: flex;
    flex-direction: row;
    float: right;
    margin-top: 5px;
  }
  .poc-complimentary{
    flex-direction: row;
    /* float: right; */
    margin-top: 19px;
    margin-left: 40px;
  }