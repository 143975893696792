.theme-light .container__wrap {
    overflow: hidden !important;
}

.dashboard-title {
    font-size: 12px;
    color: #000;
    line-height: 18px;
    word-break: break-word;
    width: max-content;
}

.sub_card.col {
    padding-left: 5px;
}

.status-data .new-card-details {
    width: 30%;
    padding-left: 15px;
    margin-bottom: 5px;
    margin-right: 8px;
}

.dashboard_document {
    font-size: 26px;
    color: #000;
    right: 0;
}

.recent-cardbody {
    color: #000;
    font-size: 18px;
    margin: 0px 0px 20px 0px;
    font-weight: 500;

}

.recent1 {
    border: 1px solid #6540ee;
    border-radius: 3px;
}

.todo-cardbody .todo {
    color: #000;
    font-size: 18px;
    margin: 0px 0px 10px 0px;
    font-weight: 500;
}

.alerts {
    display: flex;
}

.recent-cardbody ul .alerts {
    list-style: none;
    position: relative;
    height: fit-content !important;
    border: 0;
    padding: 10px 10px 10px 0px;
}

.recent-cardbody ul .alerts:before {
    content: "";
    position: absolute;
    top: 0;
    background: #bbb;
    width: 2px;
    height: 100%;
}

.recent-cardbody ul .alerts:after {
    content: "";
    position: absolute;
    top: 17px;
    background: #4481f1;
    width: 10px;
    height: 10px;
    border-radius: 50px;
    left: -4px;
}

.card-body {
    padding: 15px 20px;
    flex: none;
    height: auto;
}

.new-card {
    padding-bottom: 10px;
    height: 87%;
    border-radius: 3px;
    border: 1px solid #6540ee;
}

.new-card-details {
    height: fit-content;
    background: transparent;
    padding: 5px 10px;
    border-radius: 2px;
    border: solid 1px #6540ee;
}

.status-data {
    width: 30%;
}

.digit {
    font-size: 22px;
    color: #000;
    font-weight: 500;
    line-height: 34px;
}

.documents-cardBody {
    display: block;
    border-radius: 4px;
}

.heading_card_left.col {
    font-size: 18px;
    color: #000000;
    font-weight: 500;
}

.heading_card_right.col {
    font-size: 20px;
    color: #000000;
    font-weight: 500;
    font-family: roboto;
}

.heading_card {
    max-height: 25px;
    margin-bottom: 12px !important;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.refer-cardBody {
    background: rgba(249, 164, 89, 0.06) !important;
}

.icon {
    font-size: 30px;
    color: #fff;
    width: 100px;
    height: 100px;
    margin: -20px -25px -25px -25px;
}

.token-icon {
    color: #fff;
    width: 53px;
    height: 53px;
    margin: 1px 0px 0px 0px;
    padding: 6px;
}

.recent-icon {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    padding: 5px;
}

.alert-icon {
    width: 60px;
    height: 60px;
    margin: 0px;
    border-radius: 50%;
    padding: 5px;
}

.recent {
    font-size: 20px;
    font-weight: bold;
    color: #000000;
}

.history-tl-container {
    font-family: 'Poppins', sans-serif;
    display: block;
    position: relative;
}

.history-tl-container ul.tl {
    padding: 0;
    width: 100%;
    display: inline-block;
}

.history-tl-container ul.tl li {
    list-style: none;
    margin: auto;
    min-height: 50px;
    border-left: 3px solid #fff;
    padding-left: 20px;
    position: relative;
}

.history-tl-container ul.tl li:last-child {
    border-left: 0;
    left: 3px;
}

.history-tl-container ul.tl li:first-child::before {
    background: #fff;
    margin: -10px 0px 10px 0px;
}

.history-tl-container ul.tl li::before {
    position: absolute;
    left: -7px;
    top: -5px;
    content: " ";
    border: 4px solid #fff;
    border-radius: 500%;
    background: #ffffff;
    height: 12px;
    width: 12px;
    transition: all 500ms ease-in-out;
}

.history-tl-container ul.tl li:last-child {
    border: none !important;
}

.history-tl-container ul.tl li:nth-child(2)::before {
    background: #fff;
    border: 4px solid #DBDBE0;
    margin: -11px 0px 10px 0px;
}

.history-tl-container ul.tl li:nth-child(3)::before {
    background: #fff;
    border: 4px solid #DBDBE0;
    margin: -11px 0px 10px 0px;
}

.history-tl-container ul.tl li:nth-child(4)::before {
    background: #fff;
    border: 4px solid #DBDBE0;
    margin: -11px 0px 10px 0px;
}

.history-tl-container ul.tl li:nth-child(5)::before {
    background: #fff;
    border: 4px solid #DBDBE0;
    margin: -11px 0px 10px 0px;
}

.history-tl-container ul.tl li:nth-child(2) {
    list-style: none;
    margin: 20px 0px 10px 0px;
    border-left: 3px solid #DBDBE0;
    min-height: 50px;
    padding-left: 20px;
    position: relative;
}

.history-tl-container ul.tl li:nth-child(3) {
    list-style: none;
    margin: 20px 0px 10px 0px;
    min-height: 50px;
    border-left: 3px solid #DBDBE0;
    padding-left: 20px;
    position: relative;
}

.history-tl-container ul.tl li:nth-child(4) {
    list-style: none;
    margin: 20px 0px 10px 0px;
    min-height: 50px;
    border-left: 3px solid #DBDBE0;
    padding-left: 20px;
    position: relative;
}

.history-tl-container ul.tl li:nth-child(5) {
    list-style: none;
    margin: 20px 0px 10px 0px;
    min-height: 50px;
    border-left: 3px solid #DBDBE0;
    padding-left: 20px;
    position: relative;
}

ul.tl li .item-title {
    position: relative;
    top: -32px;
    font-weight: 500;
    font-size: 14px;
}

ul.tl li .todoitem-title {
    position: relative;
    top: -21px;
    font-size: 15px;
    color: #fff;
}

.item-subtitle {
    color: #7a7a7a;
    font-size: 15px;
    margin-left: 17% !important;
    margin-top: 0px;
}

.ren {
    margin-left: 17% !important;
    text-align: justify;
    position: relative;
    font-size: 16px;
    color: #404040;
}

ul.tl li .item-detail {
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
}

.list-icon {
    background: #9932CC;
    color: #fff;
    padding: 12px 12px 13px 12px;
    border-radius: 50%;
    margin-right: 8px;
    font-size: 22px;
}

.recent-cardbody ul li {
    list-style: none;
    position: relative;
    height: 54px !important;
    border-bottom: 1px solid #eee;
}

.todo-cardbody ul li {
    border: none;
}

.recent-cardbody {
    min-height: 60vh;
    width: 100%;
}

.recent-cardbody ul {
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: 5px !important;
}

.todo-cardbody {
    width: 100%;
    min-height: calc(30vh - 8px);
}

.recent-username {
    position: absolute;
    left: 4%;
}

.chart-container canvas {
    height: 150px;
    width: 150px;
    max-height: 125px !important;
}

.status-data {
    display: flex;
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px#F0F1FD;
    border-radius: 10px;
    background: transparent;
}

.alertsCardWrap .alerts-heading {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    display: block;
}

.alertStatsWrap .dashboard-title {
    font-size: 12px;
}

.alertsCardWrap {
    height: 40vh !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #808080 !important;
    border-radius: 10px;
}

.bg-primary {
    background: #A2CBFB !important;
}

.bg-primary2 {
    background: #F6B47E !important;
}

.bg-primary3 {
    background: #B7AFF9 !important;
}

.bg-primary4 {
    background: #F399C1 !important;
}

.bg-primary5 {
    background: #81CCFE !important;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) {
    .item-subtitle {
        margin-left: 26% !important;
        margin-top: 0px;
    }

    .ren {
        margin-left: 26% !important;
    }

    ul.tl li .item-title {
        position: relative;
        top: -55px;
        font-weight: 500;
        margin-left: -10px;
    }

    .ren span {
        margin-left: 26% !important;
    }

    .recent-cardbody ul li {
        height: 80px;
        border-bottom: none;
    }

    .recent-cardbody ul .alerts {
        display: flow-root;
    }

    .theme-light .container__wrap {
        width: 1025px !important;
        height: 822px !important;
    }

    .documents-cardBody {
        display: block;
    }
}

@media only screen and (min-device-width: 360px) and (max-device-width:600px) {
    .ren {
        margin-left: 25% !important;
    }

    .item-subtitle {
        margin-left: 25% !important;
        margin-top: 0px;
    }
}

@media only screen and (max-device-width: 320px) {
    .ren {
        margin-left: 25% !important;
    }

    .item-subtitle {
        margin-left: 25% !important;
        margin-top: 0px;
    }

    ul.tl li .item-title {
        position: relative;
        top: -55px;
        font-weight: 500;
        font-size: 14px;
        margin-left: -10px;
    }

    .ren span {
        margin-left: 25% !important;
    }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) {
    .new-contract-icon {
        margin-left: -12px;
    }

    .token-icon {
        margin-left: -12px;
    }

    .Contracts-icon {
        margin-left: -12px;
    }

    .documents-cardBody {
        display: block;
    }
}

@media only screen and (min-device-width:1024px) and (max-device-width:2280px) and (orientation: portrait) {
    .item-subtitle {
        margin-left: 25% !important;
        margin-top: 0px;
    }

    .ren {
        margin-left: 25% !important;
    }

    ul.tl li .item-title {
        position: relative;
        top: -55px;
        font-weight: 500;
        font-size: 14px;
        margin-left: -10px;
    }

    .ren span {
        margin-left: 25% !important;
    }
}

@media (max-width:1400px) {

    .dashboard_document,
    .pageHeader h3 {
        font-size: 18px;
    }

    .alert-font-color {
        font-size: 8px;
    }

    .alerts-heading,
    ul.tl li .todoitem-title,
    .dashboard-title {
        font-size: 10px !important;
    }

    .recent-cardbody .recent,
    .todo-cardbody .todo {
        font-size: 12px;
    }

    .digit {
        font-size: 18px;
        line-height: 20px;
    }

    .heading_card_right.col,
    .heading_card_left.col {
        font-size: 13px;
    }

    .documents-cardBody {
        padding: 15px 15px;
    }

    .documents-cardBody .sub_card,
    .documents-cardBody .col {
        padding-left: 10px;
    }

    .documents-cardBody .sub_card:first-child,
    .documents-cardBody .col:first-child {
        padding-left: 15px;
    }
}

.status-card-alignment {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
}

@media screen and (max-width:568px) {
    .status-card-alignment {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }
}

.new-alert-details {
    margin: 0 0 0 4%;
    height: fit-content;
    width: 100%;
}

.alerts-heading {
    font-size: 14px;
    color: #000;
}

.alert-font-color {
    color: #999;
    font-size: 12px;
    display: block;
    text-align: right;
}

#messagesCard {
    border-radius: 3px;
    max-height: 480px;
    margin-top: 0px;
    margin: 0px 3px 0px 3px;
}

#locationCard {
    border-radius: 3px;
    max-height: 480px;
    margin-top: 0px;
    margin: 0px 3px 0px 3px;
}

.dropdown {
    height: 10px;
    width: 10px;
    margin-left: 2px;
}

.card-custom-title {
    font-weight: bold;
    font-size: 19px;
    border-radius: 5px;
    cursor: pointer;
    background-color: rgb(99, 128, 467);
    color: white;
}

.sub-menu-item {
    margin-left: 60px;
}

.accordian-arrow {
    display: inline-block;
    float: right;
}

.dashborad-row {
    margin: 10px 20px 0px 20px;
}

.pointer {
    cursor: pointer;
}

ion-segment-button {
    --background-checked: var(--ion-color-primary);
    --color-checked: var(--ion-color-primary-contrast);
    --indicator-color: transparent !important;
}

.dashboard-ig-main {
    height: 100vh;
}

.dashboard-ig-height {
    height: 100vh;
}

.dashboard-card-content {
    background-color: white;
    height: 100%;
}

.header-ion-button {
    margin-right: 5px;
}

.menu-tab {
    padding: 0;

    .clickableText {
        --background: #22212d;
        color: white;
        --background-hover: white;

        &:hover {
            color: white;
        }

        &:active {
            color: white;
        }
    }
}

.location-accordian-grp {
    width: 100%;
}

.location-accordian {
    width: 100%;
    background-color: black !important;
}

.location-item-container {
    color: white;
    width: 184px;
}

.accordian-location-header {
    color: white;
    --background: #22212d !important;

    .ion-accordion-toggle-icon {
        color: white;
    }
}

.selected-location {
    background-color: #FFF;
    color: #000;
}

.header-market-name {
    color: white;
    display: flex;
    text-transform: capitalize;
    font-size: 17px;
    vertical-align: super;
    width: min-content;
    overflow-wrap: break-word;
    align-content: center;
    padding-left: 1em;
    white-space: nowrap;
    overflow: hidden;
    margin-bottom: 12px;
}
.hpsidenav-items {
    cursor: pointer;
    padding: .5rem;
    text-align: center;
    font-size: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    ion-img {
        margin: .5rem .5rem .25rem .5rem;
        min-height: 20px;
        min-width: 32px;
        height: 40px;
        // filter: brightness(0) saturate(100%) invert(48%) sepia(9%) saturate(2157%) hue-rotate(224deg) brightness(93%) contrast(85%);
    }
    
    svg {
        height: 28px;
        width: 28px;
        fill: #4A4953;
    }
    
    .sidenav-label {
        font-weight: 500;
        text-align: center;
        color:#4A4953;
    }
}

.active-nav {
    cursor: pointer;

    .sidenav-label {
        color: white;
    }

    svg {
        height: 28px;
        width: 28px;
        fill: white;
    }

    background-color: var(--ion-color-primary);;
}

.active-nav-item {
    cursor: pointer;

    .sidenav-label {
        color: white;
    }

    svg {
        height: 28px;
        width: 28px;
        fill: white;
    }

    background-color: var(--ion-client-portal-default);
}

.cpsidenav-items {
    cursor: pointer;
    padding: .5rem;
    text-align: center;
    font-size: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    
    ion-img {
        margin: .5rem .5rem .25rem .5rem;
        min-height: 20px;
        min-width: 32px;
        height: 40px;
        // filter: brightness(0) saturate(100%) invert(48%) sepia(9%) saturate(2157%) hue-rotate(224deg) brightness(93%) contrast(85%);
    }
    
    svg {
        height: 28px;
        width: 28px;
        fill: #4A4953;
    }
    
    .sidenav-label {
        font-weight: 500;
        text-align: center;
        color:#4A4953;
    }
}

.cpsidenav-items:hover {
    .sidenav-label {
        color: white;
    }

    svg {
        height: 28px;
        width: 28px;
        fill: white;
    }

    background-color: var(--ion-color-success-contrast);
}
.header-last-inventory{
    margin-bottom: 8px;
}
.header-user-menu{
    padding-bottom: 8px;
}
.sidenav-item-dimension{
    width: 64px;
    height: 64px;
}