.brand-logo {
  height: 120px;
  background-color: #4A4953;
  border-radius: 5px;
  padding: 4px;
}

.site-logo-back {
  background-color: #4A4953;
  border-radius: 5px;
}

.logo-subheader {
  font-weight: bold;
  color: #727179;
  font-size: 14px;
}

.color-logo-short-note {
  font-size: 12px;
  position: absolute;
  float: clear;
  color: #727179;
  margin-top: 2px;
  line-height: 17px;
  width: 105px;

}

.logo {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.button-text-property {
  text-transform: capitalize;
  font-weight: bold;
}

.logo-input-url {
  width: 18em;
  border: 1px solid gray;
  border-radius: 5px;
  font-size: 15px;
  padding-left: 5px !important;
}

.color-logo-input-size {
  --highlight-color-focused: none !important;
  height: 35px;
}

.url-header {
  margin: 0 14px;
  color: #727179;
}

.mobile-url-header {
  color: #727179;
}

.logo-url-header {
  width: 92%;
}

.url-button {
  text-transform: capitalize;
  font-weight: bold;
  width: 70px;
  height: 45px;
}

.color-logo-url-btn {
  margin: 0 0 4px;
  text-transform: capitalize;
  font-weight: bold;
  width: 70px;
}

.short-logo-note {
  font-size: 12px;
  color: #727179;
}

.short-logo-note-mobile {
  font-size: 11.5px;
  color: #727179;
}

.short-logo-note-top {
  margin-top: -17px;
}

@media (min-width: 300px) and (max-width: 576px) {
  .short-note-parent {
    margin-left: 0px;
  }

  .short-note {
    font-size: 12px;
    color: #727179;
    margin-top: 1px;
  }

  .logo-input-url {
    margin: 0 10px 0 0;
    height: 38px;
    width: 18em;
    border: 1px solid gray;
    border-radius: 5px;
    text-align: left;
  }
}

.color-use-button {
  text-transform: capitalize;
  font-weight: bold;
  width: 45px;
}

.url-error-label {
  color: red;
  font-size: medium;
}