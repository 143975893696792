.order-btn {
  margin-top: 11px;
  background-color: var(--ion-color-primary);
  width: 50%;
  height: 33px;
  color: #ffffff;
  font-size: 14px;
  border-width: 0px;
  border-radius: 2px;
}

.order-sidenav-divider {
  background-color: var(--ion-color-light-shade);
}

.order-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.orders-left-panel {

  .pl-15 {
    padding-left: 15px;
  }

  .orders-title {
    float: left;
    margin-top: 10px;
    padding: 0px !important;
  }

  .order-btns {
    display: flex;
    flex-direction: row;
    float: right;
    margin-top: 5px;
    margin-left: 40px;
  }

  .settings-form {
    float: left;
    padding: 0px !important;
  }

  .text-left {
    text-align: left
  }

  .input-item {
    border: 1px solid lightgray;
    border-radius: 6px;
    --min-height: 0px !important;
    // width:90%
  }

  .m0 {
    margin: 0px !important;
  }

  .mt6 {
    margin-top: 6px;
  }

  .mt4 {
    margin-top: 4px;
  }

  .form-content {
    overflow: unset !important;
  }

  ion-item[slot="header"] {
    font-weight: 700;
  }

  .item {
    --background: transparent;
    cursor: pointer;

    .sc-ion-label-md-h {
      font-size: 17px !important
    }

    .sub-item {
      font-size: 14px !important;
      font-weight: 400;
      --background: transparent;
      word-break: break-word;
      white-space: break-spaces;
    }

    .sub-item-spacing {
      font-size: 14px !important;
      font-weight: 400;
      --background: transparent;
      word-break: break-word;
      white-space: pre;
    }
  }

  .row-horizontal {
    margin-top: 10px;
    width: 210px;
  }

  .solid {
    border-top: 1px solid #999;
  }

  .accordion-bg {
    background: transparent;
    padding: 0px !important;
  }

  .accordion-header {
    --background: transparent;
  }

  .settings-sidenav {
    overflow-y: scroll;
    overflow: auto;
    height: calc(100vh - 50px);
  }

  .error-asterik-size {
    width: 7px;
  }

  .text-box-width {
    font-size: 13px
  }

  .active {
    --background: #fff;
  }


}

.order-prevent-inv-banner {
  z-index: 999999;
}

.prevent-inventory-banner-content {
  /* Black background with 0.5 opacity */
  background: #f93;
  color: #000000;
  width: 100%;
  text-align: center;
  padding: 5px;
  font-weight: 600;
  margin-top: -2px;

  .order-float-right {
    float: right;
    margin-top: 9px;
  }
}
.order-number-row-tablet {
  margin-top: 10px;
  width: 208px;
}
.total-expense-content {

  color: #000000;
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: 600;
  position: relative;
  top: 20%;

  .order-float-right {
    float: right;
    margin-top: 9px;
  }
}

.no-orders-label {
  margin-top: 10%;
  display: flex;
  justify-content: center;

  b {
    font-size: 25px;
  }
}


.orderNav-icon {
  max-width: max-content !important;
  top: 30px;
}

.status-text-view {
  padding: 0px;
}

.no-orders-mobile {
  right: 7px;
  margin-top: 40%;
  display: flex;
  justify-content: center;

  b {
    font-size: 22px;
  }
}

.total-expense-mobile {

  color: #000000;
  width: 100%;
  height: 50px;
  text-align: center;
  font-weight: 600;
  position: relative;
  top: 20%;

  .order-float-right {
    float: right;
    margin-top: 9px;
  }
}

.order-form-footer {
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 6%;
  z-index: 100;
  background-color: var(--ion-color-light-shade);
  text-align: center;
  border-top: 3px solid lightgray;
}

.order-form-footer-tablet {
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 4%;
  z-index: 100;
  background-color: var(--ion-color-light-shade);
  text-align: center;
  border-top: 3px solid lightgray;
}

.sidenav-size {
  max-width: 235px;
}

.order-number-row {
  margin-top: 10px;
  width: 270px;
}

.order-btn-size {
  width: 64px;
}
.order-list-container-mobile {
  height: calc(100% - 55px);
  overflow: auto;
  overflow-y: scroll;
}

.order-list-container {
  height: calc(100% - 105px);
  overflow: auto;

  .toolbar-container {
    margin-bottom: 1px;
  }

  .grid-container {
    height: 100%;
    margin-bottom: -2px;
  }

  .mobile-grid-container {
    height: 100%;
    margin-bottom: -20px;
  }
}