.select {
    width: 90%;
}

.dropdown-checkbox {
    height: 20px;
    width: 20px;
}

.labels{
    margin-left: 20px;
}