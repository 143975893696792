.reports-sidenav {
    overflow-y: scroll;
    height: calc(100vh - 50px);
    background-color: #EAEAEB;
    border-right: #EAEAEB 1px solid;
}

.report-right-section {
    padding: 10px 15px 10px 15px
}


.report-bar {
    background: #EAEAEB;
}

.report-bar-buttons {
    display: flex;
}

.report-gray-bar {
    background: #EAEAEB;
    height: 50px;
}

.report-right-icon-row {
    text-align: center;
    margin-top: 10px;
}

.report-select-report-icon {
    height: 100px;
    width: 100px;
}

.report-align-center {
    text-align: center;
}

.report-select-report-lbl {
    font-size: 22px;
    font-weight: bold
}

.report-run-report {
    display: flex !important;
    flex-direction: column;
    margin-left: 10px;
}

.report-to-excel {
    margin-left: auto;
}

.report-to-excel-mobile {
    width: 100%;
}

.report-run-filter {
    display: flex !important;
    flex-direction: column;
}

.report-run-report-btn {
    height: 45px;
    margin-left: 8%;
    width: 85%;
    margin-top: 20px;
}

.report-date-picker {
    --border-radius: 10px;
}

.date-picker-modal {
    display: flex;
    flex-direction: row;
    overflow: auto;
    height: 52vh;
}

.chip {
    --color: black;
    --background: #FFFFFF;
    border: 1px solid #eaeaeb;
}

.tag-input {
    background: #FFFFFF;
    border-radius: 4px;
}

.cancel-date-modal {
    text-transform: capitalize;
    margin-left: 12em;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: gray;
    width: 120px;
}

.date-modal-button {
    text-transform: capitalize;
    margin-left: 6em;
    width: 120px;
}

.warning-label {
    font-size: 13px;
    border-radius: 5px;
}

.warning-col {
    top: 30px;
}

.reports-h6 {
    margin-bottom: 0px;
    font-size: 14px;
    margin-top: 5px;
}

.tagged-input-font-size {
    font-size: 13px;
}

ion-modal#date-range {
    --width: fit-content;
    --min-width: 210px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    padding-left: 90px;
    padding-top: 120px;
}

#date-range-mobile {
    --width: fit-content;
    --min-width: 210px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
    padding-left: 90px;
    padding-bottom: 120px;
}


.date-range-label {
    padding-top: 5px;
    margin-top: 5px;
    margin-bottom: 0px;
    padding-bottom: 0px;
    font-size: 13px;
}

.date-range-dropdown {
    background: #FFFFFF;
    border-radius: 4px;
}

.report-list-items {
    font-size: 15px !important;
    vertical-align: middle;
    letter-spacing: 0.02em;
    padding-bottom: 0;
    padding-left: 0.1em;
    padding-right: 1.1em;
}

ion-modal#time-range {
    --width: fit-content;
    --min-width: 250px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.reports-header-text {
    font-size: 22px !important;
    vertical-align: middle;
    font-weight: bolder !important;
    letter-spacing: 0.02em;
    padding-bottom: 0;
    padding-left: 0.1em;
    padding-right: 1em;

}

.mobile-reports-header-text {
    font-size: 17px !important;
    vertical-align: middle;
    font-weight: bolder !important;
    letter-spacing: 0.02em;
    padding-bottom: 0;
    padding-left: 0.5em;
}

.tablet-reports-header-text {
    font-size: 20px !important;
    vertical-align: middle;
    font-weight: bolder !important;
    letter-spacing: 0.02em;
    padding-bottom: 13px;
    padding-left: 0em;
}

.back-to-reports-btn {
    margin-top: 8px;
    font-size: 14px;
}

.mobile-back-to-reports-btn {
    font-size: 14px;
    text-align: center;
}

.time-dropdown-input {
    background-color: #FFFFFF;
    border-radius: 4px;
}

.select-time {
    width: 100px;
}

#date-range {
    --width: fit-content;
    --min-width: 210px;
    --height: fit-content;
    --border-radius: 6px;
    --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.active-custom-range {
    --background: var(--ion-color-primary);
    color: #FFFFFF;
}

.custom-range-items {
    margin: 0;
    --min-height: 30px;
    font-size: 14px
}

.custom-range-label {
    margin-left: 10px;
}

.date-range-footer-background {
    background: var(--ion-color-primary);
}

.date-range-list {
    border-right: 3px var(--ion-color-primary) solid;
}

.date-range-btn {
    background: #FFFFFF;
    color: #000000;
    border-radius: 5px
}

.custom-interface {
    --width: fit-content;
}

.time-rage-dropdown {
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 4px;
    background: #FFFFFF;
}

.marginTop10 {
    margin-top: 10px;
}

.marginRight10 {
    margin-right: 10px;
}

.paddingLeft10 {
    padding-left: 10px;
}

.datagrid-date-label {
    margin: 8px 8px;
    font-weight: bold;
    margin-top: 40px;
}

.tablet-date-center {
    text-align: center;
}

.no-data-placeholder {
    margin-top: 18%;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
}

.date-range-input {
    outline: auto;
    outline-color: grey;
    text-align: justify;
    width: 80%;
    height: 34px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

.custom-date-popup {
    --width: 90%;
    --height: 315px;
    --border-radius: 5px;
}

.date-modal-content {
    margin: 70px 20px;
    width: 360px;
}

.date-range-header {
    margin-left: 110px;
}

.date-modal-save {
    text-transform: capitalize;
    margin-left: 15px;
    width: 90%;
}

.from-date-row {
    margin-top: 70px;
    margin-left: 40px;
}

.to-date-row {
    margin-left: 40px;
}

.date-modal-cancel {
    text-transform: capitalize;
    margin-left: 3px;
    border-radius: 4px;
    background-color: #FFFFFF;
    color: gray;
    width: 90%;
    margin-left: 15px;
}

.custom-date-error {
    color: red;
    margin-left: 50px;
    font-size: 12px;
}

.report-side-nav-icon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 28px;
    margin-top: -2px;
}

.tablet-no-data-placeholder {
    margin-top: 40%;
    display: flex;
    justify-content: center;
    font-size: 25px;
    font-weight: bold;
}

.report-side-nav-icon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 28px;
    margin-top: -2px;
    display: flex;
}

.text-align-center {
    text-align: center;
    //padding-left: 55px;
    margin-bottom: -1em;
}

.show-datagrid-date-label {
    margin: 8px 1px;
    font-weight: bold;
    margin-top: 1px;
}

.mobile-text-allign-center {
    text-align: center;
}


.report-side-nav-icon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 28px;
    margin-top: -2px;
    display: flex;
}

.text-align-center {
    text-align: center;
    //padding-left: 55px;
    margin-bottom: -1em;
}

.show-datagrid-date-label {
    margin: 8px 1px;
    font-weight: bold;
    margin-top: 1px;
}

.mobile-text-allign-center {
    text-align: center;
}

.spinner-circles-tablet {
    left: 60%;
    top: 20%;
    margin-left: -4em;
}

.warning-label-mobile {
    font-size: 11px;
    width: 310px;
    margin-left: 20px;
    text-align: center;
}

.time-range-error {
    color: red;
    margin-top: 5px;
    font-size: 14px;
}

.mobile-time-range {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

.grid-toolbar-tbl-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

#reporting-grid {

    .ig-data-grid-toolbar,
    .igr-data-grid-toolbar span {
        font: optional !important;
        font-size: 14px !important;
        font-weight: bold !important;
        color: var(--ion-color-primary);
    }

    .igr-data-grid-toolbar svg {
        fill: var(--ion-color-primary)
    }

    .igr-column-chooser,
    .ig-checkbox-composite svg {
        background-color: var(--ion-color-primary);
        border-color: var(--ion-color-primary);
    }

    .ig-checkbox-box label {
        border-color: var(--ion-color-primary) !important;
    }
}