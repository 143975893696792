* {
  font-family: Roboto;
}

#root {
  // Scss variables which we gonna assign using
  // useState and JavaScript in reactJS
  $background-color: red;
  $text-color: #262833;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-template-rows: auto;
}

.container-card {
  background-color: var(--background-color);
}

.toolbar-title-default {
  background-color: var(--background-color);
}

.button-save {
  background-color: var(--background-color);
}

.toast-container {
  --width: 300px !important;
}

.spinner-circles {
  left: 50%;
  top: 50%;
  margin-left: -4em;
}

.swap-items {
  cursor: pointer;
}

@media (min-width: 1200px) {
  .zero-column-padding {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

@supports ((-webkit-margin-start: 0) or (margin-inline-start: 0)) or (-webkit-margin-start: 0) {
  .zero-column-padding {
    padding-inline-start: 0;
    padding-inline-end: 0;
  }
}

.fixed-bottom-container {
  position: fixed;
  bottom: 0;
}

.logout-btn {
  border-top: 1px solid gray;
  width: 100%;
  padding: 0px;
  text-align: center;
}

.note-bold-12 {
  font-size: 12px;
  font-weight: bold;
}
.market-questions {
  margin-top: 0px;
  padding-top: 0px;
  padding-left: 12px;
  margin-left: 12px;
}
.bold-h2 {
  font-weight: bold;
  color: #000000;
}

.bold-h2-mobile {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.all-border-ion-item-input {
  border: 1px solid #bbb;
  border-radius: 5px;
  // margin-bottom: 15px;
}

.content-middle {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-top: 0px;
}

.error-asterik {
  width: 8px;
  vertical-align: text-bottom
}

.validation-error-label {
  color: #ff0000;
}

.guard-restrict {
  --max-width: 400px !important;

  .alert-head {
    background-color: var(--ion-color-primary);
  }

  .alert-title {
    color: #ffff
  }

  .alert-message {
    padding: 20px !important;
  }

  .alert-button-group {
    background-color: #d7d8da;

    .cancel-btn {
      color: #000000;
      text-overflow: ellipsis;
      text-transform: capitalize;
      border-radius: 4px;
    }

    .confirm-btn {
      color: #FFFF;
      background-color: var(--ion-color-primary);
      text-overflow: ellipsis;
      text-transform: capitalize;
      width: 60px;
      text-align: center;
      border-radius: 4px;
    }

    .alert-button-inner {
      justify-content: center !important;
    }
  }

}

.header-inventory-span {
  color: #ffff !important;
}

.header-inventory-span-margin {
  padding-left: 2px;
  padding-top: 16px !important;
}

.header-inventory-banner-content {
  background: rgb(255, 153, 51);
  color: #000000;
  width: max-content;
  height: 55px;
  text-align: center;
  padding: 5px;
  font-weight: 600;

}


.header-user {
  color: #ffff;
}

.header-caret-down {
  color: #ffff;
  font-size: 25px;
}

.red-class {
  color: #ff0000;
}

.green-class {
  color: green;
}

.validation-text {
  line-height: 0.50;
  padding-left: 15px;
  margin-top: -12px;
}

.site-logo {
  background-color: transparent;
  height: 55px;
  padding-left: 4px;
  padding-bottom: 2px;
}

.user-site-logo {
  height: 35px;
  aspect-ratio: 5;
  margin-bottom: 8px;
}
.cp-user-site-logo {
  height: 32px;
  aspect-ratio: 5;
  margin-left: 8px;
  margin-bottom: 8px;
}

.errorWrapper {
  display: grid;
  height: 80%;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.mobile-errorWrapper{
  display: grid;
  height: 50%;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.tablet-errorWrapper{
  display: grid;
  height: 60%;
  width: 100%;
  align-items: center;
  justify-items: center;
}

.errorMsg {
  font-size: 20px;
  color: #ff0000;
}

.mobile-errorMsg{
  font-size: 11px;
  color: #ff0000;
  text-align: center;
}

.tablet-errorMsg{
  font-size: 17px;
  color: #ff0000;
  text-align: center;
}

.tryAgainBtn {
  margin-left: 10px;
  margin-bottom: 15px;
}

.dashboard-routing-wrapper{
  background-color: #FFFFFF;
  height: 100%;
}

.click-cursor {
  cursor: pointer;
}
.needs-onboarding-count{
  color: #000000;
  min-height: 20px;
  min-width: 20px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 15px;
}
.mt-71{
  margin-top:71px
}
.client-admin-default-sel {
  background-color: var(--ion-color-primary);
  padding: 7px;
  color: #fff;
  margin: 5px;
  border-radius: 5px
}