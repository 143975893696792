#title {
  text-align: center;
  font-family: arial, sans-serif;
}

.users-table {
  text-align: center;
  font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  border: 3px solid #ddd;
  width: 100%;
}

.users-table td,
.users-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.users-table tr:hover {
  background-color: #ddd;
}

.user-form-textarea {
  width: 100%;
  height: 70px;
  text-align: left;
}

.users-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #4A4953;
  color: white;
}

.div-user-management-bg {
  background-color: whitesmoke;
  max-height: 100% !important;
  left: 0;
  width: 100% !important;  
  position: unset;
  z-index: 1;
  top: 5%;
}

.modal-header {
  background-color: var(--background-color);
}

.clickableText {
  cursor: pointer;
  color: #896BB1;
  font-weight: bold;
  text-align: left;
}

.sc-ion-modal-md-h {
  --width: 420px;
  --height: 600px;
}

.modal-form-date {
  outline: auto;
  outline-color: grey;
}

.name-color {
  color: #896BB1;
}

.delete-chip {
  color: white;
  height: 30px;
  width: 30px;
  background-color: var(--ion-color-primary);
}

.add-user {
  text-transform: capitalize;
  width: 80px;
  font-weight: bold;
  margin: 7px;
}

.name-column {
  cursor: pointer;
  color: #896BB1;
  font-weight: bold;
  text-align: left;
}

.other-column {
  text-align: left;
}

.location-text {
  font-weight: bold;
  color: var(--ion-color-primary);
  text-align: center;
}

.user-management-action {
  display: flex;
}

.user-delete {
  text-align: center;
}

.grid-container {
  height: 100%;
}

.onboarded {
  color: #EAEAEB;
  cursor: not-allowed ! important;
}
.user-management-product-list-container {
    margin:0 13px 0 4px;
    .pager-root{
      bottom: 79px !important;
    position: relative;
    }
}

.user-management-no-product {
  width: 100%;
  height: 315px;
  border: 1px solid lightgray;
  font-weight: 900;
  font-size: 30px;
  margin: auto;
  padding: 60px;
  text-align: center;
  line-height: 200px;
}

.user-management-no-product-mobile {
  width: 100%;
  height: 315px;
  border: 1px solid lightgray;
  font-weight: 900;
  font-size: 20px;
  margin: auto;
  padding: 40px;
  text-align: center;
  line-height: 200px;
}