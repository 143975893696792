.product-filter {
    background: #555555 !important;
}

.clear-filter {
    background-color: lightgray !important;
}

.clear-filter-background {
    background-color: #eaeaeb !important;
}

.item-bg-color {
    --ion-item-background: #eaeaeb !important;
}

.product-category-filter {
    ion-item {
        --min-height: 16px;
    }

    .cleat-filter-btn {
        margin: 14px
    }

    .removeBtn {
        background-color: lightgray
    }

}

.swap-product-banner-content {
    background: rgb(255, 0, 0);
    /* Fallback color */
    background: rgba(255, 0, 0, 0.5);
    /* Black background with 0.5 opacity */
    color: white;
    height: 68px;
    text-align: center;
    padding: 5px;

}

.error {
    font-size: 12px;
    line-height: 15px;
}

.currency-text-box {
    z-index: 9999;
    font-size: 16px;
    margin-left: 30px;
    height: 35px;
    width: 96px;
    padding:6px !important;
}
.self-operated-currency-fields {
    font-size: 16px;
    margin-left: 30px;
    height: 35px;
    width: 96px;
    padding:6px !important;
}

.product-image {
    width: 180px;
    height: 90px;
    object-fit: fill;
}
.self-product-qty{
    min-height: 33px !important;
    display: flex;
    align-items: center;
    font-size: 26px;
}