.header {
    position: fixed;
    top: 0;
    width: 100%;
    background: var(--ion-color-primary);
    color: #fff;
    z-index: 999;
}

.header-title {
    margin-top: 7px;
    font-size: 20px;
}

.modal-body {
    margin-top: 60px;
}

.product-search-box {
    border-radius: 5px;

}

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #eaeaeb;
}
.self-operated-fields{
    justify-content: flex-end;
}
.close-btn {
    font-size: 30px;
    margin-left: 20px;
}

.close-button {
    float: right;
    margin-right: 15px;
}

.cancel-button {
    cursor: pointer;
    font-size: 25px;
}

.product-list {
    margin-bottom: 70px;
}

.modal-pop {
    --width: 400px;
    --height: 90%;
    --border-radius: 10px;
}
@media(min-width:1282px){
    .modal-pop {
        --width: 400px;
        --height: 55%;
        --border-radius: 10px;
    }
}

.mobileview-modal-pop {
    --width: 400px;
    --height: 530px;
    --border-radius: 10px;
}

.text-heading {
    font-weight: bold;
}

.cleat-filter-btn {
    --box-shadow: none;
    --background-color: #fff;
}

.btn-text-cases {
    text-transform: capitalize;
}

.cleat-filter-btn,
button {
    border: 2px solid #ccc !important;
    --color: #000 !important;
    font-weight: bold;
    border-radius: 4px;
    font-size: 14px;
}

.product-item {
    padding-left: 30px;
}

.item-list {
    padding: 0;
    margin-bottom: 70px;
}

.product-prise {
    padding-right: 15px;
    font-weight: bolder;
}

.filter-section {
    box-shadow: #eceaea 1px 3px 3px 0px;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.filter-applied-text {
    color: red;
    font-style: italic;
    font-size: 14px;
}

.filter-icon {
    margin-top: 8px;
    font-size: 20px;
}

.filter-icon-btn {
    float: right;
}

.search-box {
    background-color: #727179;
    box-shadow: #727179 1px 2px 3px 0px;
    margin-top: 10px;
    margin-bottom: 3px;
    padding-bottom: 1px;
}

.close-icon {
    padding-top: 10px;
    padding-left: 9px;
    font-size: 32px;
    font-weight: bold;
    margin-top: 12px;
    cursor: pointer;
    color: rgb(255, 255, 255);
}

.sort-dropdown {
    border: 2px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    min-height: 40px !important;
    --highlight-color-focused:none !important;
}

.select-popover {
    --width: 468px;
}

.search-icon {
    height: 42px;
    margin-top: 0px;
}

.search-input {
    border-radius: 5px;
    border: none;
}

.inc {
    border: 1px solid grey;
    --background: gainsboro;
    height: 36px;
}

.refill-action-order {
    background: #fff;
    margin-top: -14px;
    text-align: center;
}

.inc-btn {
    border-right: 1px solid grey;
    height: 64px;
    width: 23px;
    margin-top: -25px;
    margin-bottom: -13px;
    font-size: 15px;
}

.inc-right-btn {
    border-left: 1px solid grey;
    height: 64px;
    width: 23px;
    margin-top: -25px;
    margin-bottom: -13px;
    font-size: 15px;
}

.modal-pop-details {
    --width: 400px;
    --border-radius: 10px;
    --height: 95%;
}

.product-heading {
    background: rgb(114, 113, 121);
    width: 100%;
    height: 40px;
    padding-top: 7px;
    color: #fff;
}

.category-name,
.category-price {
    padding-left: 13px;
}

.global-margin {
    visibility: hidden;
}

.product-details {
    ion-item {
        --min-height: 16px;
    }

    .input-item {
        border: 1px solid lightgray;
        border-radius: 6px;
        --min-height: 0px !important;
    }

    .pricing-text {
        font-size: 12px;
    }

    .selected-pricing {
        font-weight: 600;
    }

    .vertical-line-separator {
        border-left: 1px solid grey;
        height: 45px;
        padding-bottom: 25px;
    }

    .f12 {
        font-size: 12px;
    }

    .cost-section {
        color: darkgrey
    }

    .add-quatity {
        display: flex;
        height: 45px;
        width: 170px;
        border: 1px solid #ccc;
    }
    .quantity-buttons-right{
        height: 44px;
        bottom: 6px;
        right: 5px;
    }
    .quantity-value-field{
        bottom: 6px;
    }
    .quantity-buttons-left{
        height: 44px;
        bottom: 6px;
        left: 5px;
    }

    .btn {
        --background: #AEAEAE;
        color: white;
    }

    .inc-dec-size {
        width: 20px
    }

    .itemCount {
        text-align: center
    }

    .specific-margin {
        border: 1px solid lightgray;
        max-width: 42%;
        margin-left: 85px;
    }

    .header-title {
        width: 300px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .error-asterik-size {
        width: 8px;
    }
}

.grid-btns {
    --background: #E8E8E8;
    border-radius: 4px;
    color: black;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 15px;
    margin-right: 5px;
}

.grid-btns-tab {
    --background: #E8E8E8;
    border-radius: 4px;
    color: black;
    font-weight: 700;
    text-transform: capitalize;
    font-size: 13px;
    margin-right: 5px;
}

.no-products {
    display: flex;
    justify-content: center;
    align-items: center;
}

.product-publish {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 15px;
}

.product-heading-name {
    margin-top: 72px;
    text-align: center;
    margin-bottom: 8px;
}

.product-des {
    margin-left: 6px;
}

.sticky {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: #fff;
}

.list-sticky {
    margin-top: 228px;
}

.sort-by {
    margin-left: 10px;
}

.sort-select-drop-down {
    margin-left: 10px;
    margin-left: 5px;
    margin-bottom: 10px;
}

.refill-text-cases {
    text-transform: capitalize;
}

.sticky {
    position: fixed;
    z-index: 9999;
    width: 100%;
    background: #fff;
}

.list-sticky {
    margin-top: 228px;
}

.sort-by {
    margin-left: 10px;
}

.sort-select-drop-down {
    margin-left: 10px;
}

.column-count {
    padding-left: 10px;
}

.product-search-box {
    border-radius: 5px;
    height: 41px;
}

.manage-columns-side-heading {
    margin-top: 10px;
}

.search-item-box {
    bottom: 4px;
}

.search-box-col {
    margin-left: 5px;
}
.cp-product-list{
    overflow-y: auto;
    height: 400px;
}
.self-operated-footer{
    justify-content: flex-end;
}