.input-headers-email {  
    color: gray;
    margin-left: 10px;
    
  }
  .email-text-email {   
    color: black; 
    margin-left: 10px;
  }
   
  
  .ion-button-email {  
    float: right;
  }
  
  .cancel-email{  
    font-size: 30px;
    width: 10px;
    color: white;
    margin-right: 20px;
  
  } 
  
  .modal-pop-email {    
    --width: 340px;
    --height: 200px;
    --border-radius: 10px;
  }
  
  .header {  
    height: 60px;
    justify-content: center;
    top: 0;
    width: 100%;
    color: #fff;
    z-index: 999;
  }
  
  .modal-body-email {  
    margin-top: 60px;
  }
  
  .btn-text-cases-save-email {   
    justify-content: center;
  }
  .footer {  
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #eaeaeb;
  
  }
  
  .form-body-email {  
    justify-content: center;
    height: fit-content;
    width: fit-content;
  }
  .modalRowContent-email {    
    height: 60px;
    justify-content: left; 
    width: 100%;
    
  }
  .btn-email-ok {
    justify-content: center; 
    
  }
  
 
 
   
  
  
  
  