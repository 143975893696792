@media (min-width: 300px) and (max-width: 576px) {
    .login-button-section {
        margin: 50% 0px 0px 0px;
    }
    .btn-sec {
        margin-left: 30px !important;
    }
}
.tnc-p {
    margin-left: 110px;
}
.tnc-p a {
    font-size: 10px;
    color: #ffffff;
}
.btn-sec {
    margin-left: 86px;
}
.tnc {
    margin-left: 110px;
 }
.tnc a {
    font-size: 10px;
    color: #ffffff;
 }

@media (min-width: 576.1px) and (max-width: 768px) {
    .login-button-section {
        margin: 100% 0px 0px 0px;
    }
    .btn-sec {
        margin-left: 30px;
    }
    .outer {
        width: 10px;
        height: 100%;
        margin: auto;
        position: relative;
        overflow: hidden;
        background: #ffff;
    }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992.1px) and (max-width: 1200px) {
    .login-button-section {
        margin: 113% 0px 0px 0px;
    }
    .btn-sec {
        margin-left: 30px;
    }
}
// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1201.1px) and (max-width: 1440px) {
    .login-button-section {
        margin: 162% 0px 0px 0px;
    }
    .btn-sec {
        margin-left: 30px;
    } 
}