.no-product {
    width: 100%;
    height: 315px;
    border: 1px solid lightgray;
    font-weight: 900;
    font-size: 30px;
    margin: auto;
    /* width: 50%; */
    /* border: 3px solid green; */
    padding: 10px;
    text-align: center;
    line-height: 200px;
}

.no-products-mobile {
    width: 100%;
    height: 315px;
    border: 1px solid lightgray;
    font-weight: 900;
    font-size: large;
    margin: auto;
    padding: 10px;
    text-align: center;
    line-height: 202px;
}

.market-product-name {
    width: 175px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-list-container {
    margin-bottom: 6px;
    height: calc(100% - 95px);

    .toolbar-container {
        margin-bottom: 1px;
    }

    .grid-container {
        height: 100%;
    }

    .mobile-grid-container {
        height: 100%;
    }
}
.product-list-grid-section{
    .pager-root {
        bottom: 144px !important;
        position: relative;
    }
}
.grid-column-height {
    height: 100%;
}
.commit-discard-btn {
    position: absolute;
    right: 0;
    top: 0px;
    float: right;
    margin-right: 26px;
}
@media(max-width:790px){
    .commit-discard-btn {
        position: relative;
        right: 0;
        top: 0px;
        float: right;
        margin-right: 26px;
    }
}
.commit-discard-btn-tablet {
    right: 0;
    top: 0px;
    margin-right: 26px;
}

.discard-grid-container {
    display: grid;
    grid-template-columns: 1fr 80px;
}

.top {
    margin-bottom: -10px;
    margin-top: -23px;
}

.ion-chip {
    margin-left: 130px;
    margin-top: 15px;
    width: 36px;
    background-color: #eaeaeb0a;
}

.show-pannel {
    padding: 1.5em 2em;
    height: 100%;
}

.product-setting-save {
    margin-bottom: 40px;
    float: right;
}

.product-setting-save-tablet {
    margin-bottom: 240px;
}

.side-nav-icon {
    margin-right: 10px;
    vertical-align: middle;
    font-size: 28px;
}

.product-list-cancel-chip {
    font-size: 35px;
    width: 10px;
    color: black;
    margin-right: 20px;
}

.global-margin-form {
    margin-top: 15px;
}

.global-margin-form-tablet {
    margin-top: -1px;
}

.taxes {
    margin-top: 1px;
    margin-bottom: 3px;
    font-size: 14px;
    margin-left: 5px;
}

.setting-padding {
    padding-left: 3px;
}

.discontinued {
    --background-color: #EE4B2B !important;
    --background-activated: #EE4B2B !important;
    color: #EE4B2B !important;
}

.spinner-toExcel {
    top: 30%;
    margin-left: 2px !important;
    left: 10%;
}

.product-to-excel {
    height: 35px;
    margin: 2px -25px 3px 2px;
}

.product-to-excel-tab {
    margin-top: 5px;
    height: 35px;
    margin: 2px -25px 3px 2px;
    width: 60px;
    font-size: 10px;
}

.self-operated-modal-pop {
    --width: 1150px;
    --border-radius: 10px;
}

.self-operated-mobileview-modal-pop {
    --width: 1150px;
    --border-radius: 10px;
}

.self-operated-btn-width {
    width: fit-content
}

.self-operated-required-section {
    margin-top: 60px;
    padding: 10px;
}

.self-operated-product-current {
    height: 45px;
    margin-left: 0px;
}

.self-operated-qty {
    height: 45px;
}
.product-qty-buttons{
    height: fit-content;
    padding-top: 12px;
}

.self-products {
    display: flex;
    align-items: baseline;
    padding-bottom: 70px;
}

.self-products {
    .setting-form-field {
        width: -webkit-fill-available;
    }

    .mb-25 {
        margin-bottom: 35px;
    }
}

.self-products-select {
    outline: auto;
    outline-color: grey;
    text-align: justify;
    --highlight-color-focused:none !important;
}

.btncenter {
    margin: 0 auto;
    display: block;
}

.product-to-excel-mob {
    margin: 2px 5px 4px 2px;
    height: 33px;
    width: 61px;
    font-size: 11px;
}
.mt-60{
    margin-top: 60px;
}
.ml-5{
    margin-left: -5px;
}
.to-excel-product{
    right:8px;
}
.round-to-nines{
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    margin-bottom: 4px;
    font-size: 14px;
}
.product-margins-column{
    top:10px;
    left:5px;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}
.marginLeft5{
    margin-left: 5px;
}
.product-popup-content-margin{
    padding-bottom: 60px;
}

.self-operated-text-input-size{
    --highlight-color-focused:none !important;
    min-height: 40px !important;
}
.product-settings-save-btn {
    float: right;
    --background-color: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
    border-radius: 5px;
}
.product-settings-save-btn-left {
    float: left;
    --background-color: var(--ion-color-primary);
    background-color: var(--ion-color-primary);
    border-radius: 5px;
}
  