.pwd-update-screen {
  background-color: #494952 !important;
  .content{
      align-items: center;
      height: 100%;
      .login-content-padding{
          text-transform: revert;
          padding: 2em;
          text-align: right;
          max-width: 50%;
          form{
              text-align: left !important;

          }
          .pv-terms-text{
              text-align: right;
              font-size: smaller;
              a{
                text-decoration: none;
              }
              .pv-text{
                :first-child:after{
                  padding: 5px; 
                  color: black;
              }
              }
          }
          .cp-pv-terms-text{
            text-align: right;
            font-size: smaller;
            a{ 
              color: white;
            }
            .cp-pv-text{
              :first-child:after{
                padding: 5px; 
                color: white;
            }
            }
        }
      }
  }
}
.v-line{
    border-right: 4px solid white;
    height: 60%;
}
.h-line{
    border-bottom: 4px solid white;
    height:inherit;
}
.mobile{
    align-items: flex-start !important;
}
.pwd-update-form {
  margin-top: 80px;
  & h2 {
    color: #ffff;
    font-weight: 600;
  }
}

.pwd-update-logo {
  float: right;
}

.div-pwd-update-title {
  background-color: dimgrey !important;
  text-align: center;
  min-height: 100%;
  left: 0;
  width: 100%;
  position: absolute;
  max-height: 100%;
  z-index: 1;
}

.pwd-update-title {
  height: 65%;
  font-size: 50px;
  margin: auto;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  color: #ffff;
  & span {
    font-weight: 700;
  }
}

p.title {
  padding-top: 60px;
}
.pwd-update-validations {
  margin-top: 50px;
  color: #ffff;
}

ul {
  list-style-type: none;
}

ul > li:before {
  content: "–"; /* en dash */
  position: absolute;
  margin-left: -1.1em;
}
.outer {
  width: 10px;
  height: 70%;
  margin: auto;
  position: relative;
  overflow: hidden;
  background: #ffff;
}
.inner {
  position: absolute;
  width: 100%;
  height: 40%;
  background: #ffff;
  top: 30%;
  box-shadow: 0px 0px 30px 20px #ffff;
}
.btn-sec {
 margin-left: 51px;
}
.tnc {
  margin-left: 75px;
}
.tnc a {
  font-size: 10px;
  color: #ffffff;
}
.forget-password {
  border: 1px solid;
  background: #494952;
  height: 24px;
  color: #ffffff;
  border-radius: 4px;
  font-size: 10px;
}

.submit-btn {
  font-size: 10px;
  background-color: blue;
  border-radius: 4px;
  color: #ffffff;
  height: 24px;
}

.signin-form {
  width: 100%;
  height: 100%;
  margin: auto;
}

.form-content input {
 width:200px
}

.forget-email {
  border: 1px solid;
  background: #fff;
  color: black;
  min-height: 40px !important;
  --highlight-color-focused: none !important;
  padding-left: 5px !important;
}
.forget-pass-left-sec {
  text-align: left;
  color: #ffff;
}

.forget-pass-text {
  font-size: 17px;
  color: white;
}