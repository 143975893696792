.navigation-button {
  background-color: #6380ff;
  width: 200px;
  height: 33px;
  color: #ffffff;
  font-size: 12px;
  border-width: 0px;
  border-radius: 2px;
}

.navigation-row {
  height: 70%;
}

.navigation-lobby-row {
  height: 70%;
  padding: 20px;
  align-items: center;
}

.navigation-col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.admin-navigation-bg {
  height: 100%;
  background: var(--ion-color-light-shade);
}

.main-lobby {
  width: 100%;
  background: #ffffff;
  height: 70%;
}
.main-lobby-text {
  padding-left: 5px;
}
.main-lobby-button {
  margin-top: 70px;
  background-color: #6380ff;
  width: 200px;
  height: 33px;
  color: #ffffff;
  font-size: 12px;
  border-width: 0px;
  border-radius: 2px;
}
