/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
$theme: var(--ion-color-primary);

.split-pane-visible>.split-pane-side {
    min-width: 240px;
    /* Default: 270px */
    max-width: 324px;
    /* Same as when menu opens in mobile mode */
}

:root {
    --ion-font-family: 'Roboto';

    --ion-color-primary: #896bb1;
    --ion-color-primary-rgb: 137, 107, 177;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #795e9c;
    --ion-color-primary-tint: #957ab9;
    --background-activated: var(--ion-color-primary);


    --ion-color-secondary: #5260ff;
    --ion-color-secondary-rgb: 82, 96, 255;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #4854e0;
    --ion-color-secondary-tint: #6370ff;

    --ion-color-tertiary: #5260ff;
    --ion-color-tertiary-rgb: 82, 96, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #4854e0;
    --ion-color-tertiary-tint: #6370ff;

    --ion-color-success: #2dd36f;
    --ion-color-success-rgb: 45, 211, 111;
    --ion-color-success-contrast: #000000;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #28ba62;
    --ion-color-success-tint: #42d77d;

    --ion-color-warning: #ffc409;
    --ion-color-warning-rgb: 255, 196, 9;
    --ion-color-warning-contrast: #000000;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #e0ac08;
    --ion-color-warning-tint: #ffca22;

    --ion-color-danger: #eb445a;
    --ion-color-danger-rgb: 235, 68, 90;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #cf3c4f;
    --ion-color-danger-tint: #ed576b;

    --ion-color-medium: #4a4953;
    --ion-color-medium-rgb: 74, 73, 83;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #414049;
    --ion-color-medium-tint: #5c5b64;

    --ion-color-light: #eaeaeb;
    --ion-color-light-rgb: 244, 245, 248;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #d7d8da;
    --ion-color-light-tint: #f5f6f9;
    --ion-client-portal-default: #2555D9;
}

.my-button:hover,
.my-button.active {
    background-color: $theme !important;
    margin: 0 !important;
    min-height: 56px !important;
}

.margin-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.ion-button::part(test) {
    color: red;
    opacity: 1;
}

@font-face {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: normal;
    src: url('../../public/assets/fonts/Roboto-Regular.ttf');
}

.font-smaller {
    font-size: smaller !important;
    height: 48px;
    min-height: 48px;
}

.ion-buttons {
    height: auto !important;
}

.button {
    font-weight: 600;
}

.nav-col {
    background-color: #e6e6e6;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #bfbfbf;
    min-width: 64px;
    width: 64px;
}


.font12 {
    font-size: 12px !important;
}

.font14 {
    font-size: 14px !important;
}

.font18 {
    font-size: 18px !important;
}

.h-100 {
    height: 100% !important;
}

.text-right {
    text-align: right !important;
    padding-right: 0px;
}

.float-right {
    float: right !important;
}
.float-right-table {
    padding-top: 15px;
}

.bg-theme {
    background-color: white;
}

.header-text {
    font-size: 22px !important;
    vertical-align: middle;
    font-weight: bolder !important;
    letter-spacing: 0.02em;
    margin-top: .5em !important;
    padding-bottom: 0;
    padding-left: 0.1em;
    padding-right: 1.1em;
}

.w-calc-40 {
    width: calc(100% - 300px) !important
}

.color-logo-header {
    margin-top: 11px !important;
    padding-right: 0px !important;
}

.mb-9 {
    margin-bottom: 9px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.global-mobile-footer-btn {
    width: 100%;
    position: fixed;
    bottom: 48px;
    height: 55px;
    background-color: var(--ion-color-light-shade);
}

.global-mobile-footer-btn-onboarding {
    width: 100%;
    position: fixed;
    bottom: 0;
    height: 55px;
    background-color: var(--ion-color-light-shade);
}

.token-expired-mobile{
    width: 100% ;
    position: fixed;
    bottom: 0;
    height: 55px;
    background-color: var(--ion-color-light-shade);
    margin-left: -5px;
}

.product-margin-row {
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    font-size: 14px;
}
.margin-top-3{
    margin-top: 3px;
}

.margin-top {
    margin-top: 3px;
}

.marginLeft10 {
    margin-left: 10px;
}

.square-buttons {
    border-radius: 0;
    --border-radius: 0;
    height: 100%;
    --background: #9a9a9f;
    --color: white;
    width: 100px;
    margin-top: -2px;
    margin-left: 2px;
    float: right;
}

.square-buttons-mobile {
    border-radius: 0;
    --border-radius: 0;
    height: 100%;
    --background: #9a9a9f;
    --color: white;
    width: 100px;
    margin-top: 0px;
    margin-left: 2px;
    float: right;
}

.onboarding-footer {
    width: 50%;
    height: 55px;
    position: fixed;
    bottom: 0px;
    background-color: #eaeaeb;
    border-top: 3px solid lightgray;
    z-index: 10;
}

.token-expired-footer {
    width: 50%;
    height: 55px;
    position: fixed;
    bottom: 0px;
    background-color: #eaeaeb;
    border-top: 3px solid lightgray;
    z-index: 10;
    margin-left: -5px;
}

.verticle-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.onboarding-footer-col {
    display: flex;
    flex-direction: row-reverse;
}

.reverse-flex {
    flex-direction: row-reverse;
}

.scroll-content {
    overflow-y: auto;
    height: calc(100vh - 100px);
}

.scroll-content-mobile {
    overflow-y: auto;
    height: calc(100vh - 373px);
}

.auto-overflow-mobile{
    overflow-y: auto;
    height: 60vh;
}

.token-expired-desktop {
    margin-top: 20%;
}

.token-exp-tab{
    margin-top: 10%;
}

.create-password-item-input {
    border: 1px solid #bbb;
    border-radius: 5px;
    width: 45%;
    height: 50px !important;
}

.create-password-item-input-mobile {
    border: 1px solid #bbb;
    border-radius: 5px;
    width: 95%;
    height: 50px !important;
}

.create-password-tab{
    margin-top: 1%;
}