.ion-input-size {
  width: 100%;
  font-weight: bolder;
  outline: auto;
  height: 85px;
  --highlight-color-focused: none !important;
  --padding-top: 0px;
  padding-left: 5px !important;
}

.sub-header-label-font {
  font-family: "Roboto";
  font-size: 12;
  font-weight: bold;
  color: #727179
}

select {
  font-weight: bolder;
}

.inventory-checkbox-size {
  --size: 30px;
  --border-radius: 3px;
  margin-top: 3px;
}

.time-picker-start {
  height: 34px;
  font-size: 15px;
  font-weight: bold;
  width: 125px !important;
}

.guard-access-font {
  margin-top: 22px;
}

.top-0 {
  margin-top: 0px;
}

.prevent-order-tablet {
  margin-top: 5px;
}

.prevent-order-mobile {
  margin-top: 7px;
}