.fallback-center {
    text-align: center !important;
    margin: 150px 0px 0px 0px;
}

.fallback-center-error {
    text-align: center !important;
    margin: 120px 0px 0px 0px;
}

.fallback-circle {
    border-radius: 90px 90px 90px 90px;
    width: 150px;
    height: 150px;
    background-color: var(--ion-color-primary);
    margin: auto;
    color: white;
    font-size: 55px;
    padding-top: 40px;
}

.fallback-circle-mobile {
    border-radius: 90px 90px 90px 90px;
    width: 130px;
    height: 130px;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 40px;
    padding-top: 40px;
    margin: auto;
}

.fallback-circle-tablet {
    border-radius: 90px 90px 90px 90px;
    width: 150px;
    height: 150px;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 55px;
    padding-top: 40px;
    margin: auto;
}

.page-not-found {
    margin: 16px 0px 16px 0px;
}

.page-not-found-mobile {
    font-size: 15px;
    margin: 4px 0px 4px 0px;
}

.page-not-found-tablet {
    font-size: 20px;
    margin: 4px 0px 4px 0px;
}

.fallback-circle-desktop {
    border-radius: 90px 90px 90px 90px;
    width: 150px;
    height: 150px;
    background-color: var(--ion-color-primary);
    margin: auto;
    color: white;
    font-size: 55px;
    padding-top: 40px;

}

.fallback-circle-mobileview {
    border-radius: 90px 90px 90px 90px;
    width: 130px;
    height: 130px;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 40px;
    padding-top: 40px;
    margin: auto;
}

.fallback-circle-error-tablet {
    border-radius: 90px 90px 90px 90px;
    width: 150px;
    height: 150px;
    background-color: var(--ion-color-primary);
    color: white;
    font-size: 55px;
    padding-top: 40px;
    margin: auto;
}

.fallback-spinner {
    top: 50%;
    margin-left: 2px !important;
    left: 50%;
}