.minus {
    width: 30px;
    background-color: #C2C2C5;
    color: white;
    text-align: center;
}

.input-text {
    font-weight: bold;
    font-size: 24px;
    width: 100px;
}

.plus {
    width: 30px;
    background-color: #C2C2C5;
    color: white;
    text-align: center;
}

.counter-feature-mobile {
    padding-bottom: 30px;
}

.preview-mobile-textbox {
    width: 100px !important;
}