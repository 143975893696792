.settings-sidenav {
  background-color: var(--ion-color-light-shade);
  text-align: center;
  height: 100%;
  border-right-width: 1px;
  border-right-style: solid;
  border-right-color: #999999;
}

.settings-item-active {
  --ion-item-background: var(--ion-color-primary-contrast);
  margin-bottom: -1px;
}

.settings-item {
  --ion-item-background: var(--ion-color-light-shade);
  height: 3em;
  border-bottom: 1px solid gray;

}

.hide-sidenav {
  display: none;
}

.product-settings-panel {

  .pl-15 {
    padding-left: 15px;
  }

  .product-settings-title {
    float: left;
    margin-left: 10px;
    margin-top: 10px;
    height: 35px;
    padding: 0px !important;
  }

  .settings-form {
    float: left;
    padding: 0px !important;
    margin-top: 15px;
  }

  .text-left {
    text-align: left
  }

  .input-item {
    border: 1px solid lightgray;
    border-radius: 6px;
    height: 47px;
    bottom: 3px;
    left: 3px !important;
  }

  .m0 {
    margin: 0px !important;
  }

  .mt6 {
    margin-top: 6px;
  }

  .mt4 {
    margin-top: 4px;
  }

  // .form-content {
  //   overflow: unset !important;
  // }

  ion-item[slot="header"] {
    font-weight: 700;
  }

  .item {
    .sc-ion-label-md-h {
      font-size: 12px !important
    }
  }

  .row-horizontal {
    margin-top: 10px;
  }

  .solid {
    border-top: 1px solid #999;
  }

  .accordion-bg {
    background: transparent;
  }

  .accordion-header {
    --background: transparent;
    width: 200px;
  }

  .settings-sidenav {
    overflow-y: scroll;
    height: calc(100vh - 50px);
  }

  .settings-sidenav-tablet {
    overflow-y: scroll;
    height: calc(80vh - 50px);
  }

  .error-asterik-size {
    width: 7px;
  }

  .text-box-width {
    font-size: 13px
  }

  .panel-content {
    margin-top: 10px;
  }
}

.product-settings-panel-mobile {
  .pl-15 {
    padding-left: 15px;
  }

  .product-settings-title {
    float: left;
    margin-top: 10px;
    margin-left: 10px;
    padding: 0px !important;
  }

  .settings-form {
    float: left;
    padding: 0px !important;
  }

  .text-left {
    text-align: left
  }

  .input-item {
    border: 1px solid lightgray;
    border-radius: 6px;
    --min-height: 0px !important;
    // width:90%
  }

  .m0 {
    margin: 0px !important;
  }

  .mt6 {
    margin-top: 6px;
  }

  .mt4 {
    margin-top: 4px;
  }

  // .form-content {
  //   overflow: unset !important;
  // }

  ion-item[slot="header"] {
    font-weight: 700;
  }

  .item {
    .sc-ion-label-md-h {
      font-size: 12px !important
    }
  }

  .row-horizontal {
    margin-top: 10px;
  }

  .solid {
    border-top: 1px solid #999;
  }

  .accordion-bg {
    background: transparent;
  }

  .accordion-header {
    --background: transparent;
    width: 200px;
  }

  .settings-sidenav {
    overflow-y: scroll;
    height: calc(100vh - 50px);
  }

  .settings-sidenav-tablet {
    overflow-y: scroll;
    height: calc(80vh - 50px);
  }

  .error-asterik-size {
    width: 7px;
  }

  .text-box-width {
    font-size: 13px
  }

  .panel-content {
    margin-top: 10px;
    padding-left: 13px;
  }
}

.tooltip-container {
  position: relative;
}

.tooltip-box {
  // position: absolute;
  background: #EEDC82;
  color: rgba(0, 0, 0, 0.7);
  padding: 5px;
  border-radius: 5px;
  top: 35px;
  display: none;
  z-index: 999999;
  width: 191px;
  font-weight: 600;
}

.tooltip-box.visible {
  display: block;
}

.tooltip-arrow {
  position: absolute;
  top: -10px;
  left: 50%;
  border-width: 5px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.7) transparent transparent transparent;
}

.solid-line {
  border-top: 1px solid #999;
  margin-top: 6px;

  .settings-label {
    font-size: 14px !important;
    font-weight: 500;
  }
}