.reset-email-ion-item-input {
    border: 1px solid #bbb; 
    border-radius: 5px;
    margin-bottom: 5px;  
    background-color: white;
    color: black;
    height: 43px !important;
  }
.reset-email-ion-note-font {
    color : white;
    font-size: medium;
}
.reset-email-label-font {
    font-size: small; 
    color: white;
}
.reset-email-ion-row {
    margin-left: 5px;
    display: grid;
}
.reset-email-ion-row-password-must-not {
  margin-left: 5px;
  display: grid;
}
.reset-main-div {
  color: #ffff;
}
.reset-main-header {
  font-size: 30px;
}
.reset-email-text-right{
  text-align: right ;
}
.create-pass-validation {
  font-size: small;
  padding-left: 30px;
  display: grid;
}
.create-pass-validation-heading {
  font-weight: bold;
} 
.ion-eye-icon {
  color: black;
  margin-bottom: 10px;
}