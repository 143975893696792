.modal-footer {
  position: fixed;
  bottom: 0;
}

.cancel-modal {
  font-size: 15px;
}

.modal-header {
  background-color: #896BB1;
}

.user-form-input {
  width: 100%;
  border: 1px solid gray;
  border-radius: 5px;
  text-align: left;
}

.user-form {
  border: 1px solid gray;
  border-radius: 5px;
  text-align: left;
}

.user-form-location {
  border: 1px solid gray;
  width: 100%;
  height: 90px;
  border-radius: 5px;
}

.modal-btn {
  float: right;
  width: 18%;
  padding-left: 7px;
}

.ion-button {
  float: right;
  margin-top: 0px;
}

.ion-button-delete {
  float: right;
  background-color: #FA4C5B;
}

.cancel {
  font-size: 30px;
  width: 10px;
  color: white;
  margin-right: 20px;
}
.dismissed {
  margin-top: 20px;
}

.cancel-chip {
  font-size: 30px;
  width: 10px;
  color: white;
  margin-right: 10px;
}

.user-management-modal-pop {
  --width: 24.688em;
  --border-radius: 10px;
  padding-top: 10px;
  padding-bottom: 35px;
}

.header {
  height: 60px;
  justify-content: center;
  top: 0;
  width: 100%;
  color: #fff;
  z-index: 999;
}

.modal-body {
  margin-top: 60px;
}

.text-heading {
  font-weight: bold;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #eaeaeb;
}

.form-body {
  width: 24.688em;
  overflow: hidden;
  overflow-y: auto;
  margin-top: 15%;
  margin-bottom: 14%;
}

.delete-header {
  height: 85px;
  justify-content: center;
  top: 0;
  width: 100%;
  background: #FA4C5B;
  color: #fff;
  z-index: 999;
}

.delete-modal-pop {
  --width: 340px;
  --height: 300px;
  --border-radius: 1px;
}

.btn-text-cases-cancel {
  text-transform: capitalize;
  margin-left: 3px;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: gray;

}

.btn-text-cases-save {
  text-transform: capitalize;
  margin-left: 3px;
}

.cancel-btn-1 {
  text-transform: capitalize;
  margin-left: -1px;
  width: 115px;
  border-radius: 4px;
  background-color: #FFFFFF;
  color: gray;
  font-weight: bold;
}

.delete-btn {
  width: 120px;
  margin-left: 13px;
  background-color: #FA4C5B;
  border-radius: 4px;
  text-transform: capitalize;
  font-weight: bold;
}

.form-delete-body {
  height: 400px;
  width: 370px;
  margin-top: 10px;
  margin-left: 5px;
}

.unable-ok-btn {
  width: 150px;
  font-weight: bold;
  text-transform: uppercase;
}

.unable-delete-style {
  margin-top: 10px;
}

.unable-title {
  margin-top: 8px;
  margin-bottom: 8px;
}

.unable-header-bold {
  font-weight: bold;
}

.edit-title {
  margin-top: 8px;
}

.span-property {
  color: red;
}

.input-headers {
  color: gray;
}

.segment {
  border-radius: 5px;
  margin: auto;
  height: fit-content;
  width: 95%;
  background-color: #EAEAEB;
}

.segment-input {
  font-weight: bold;
}

.delete-name {
  text-transform: uppercase;
  font-weight: bold;
}

.delete-text-bold {
  font-weight: bold;
}

.line-break {
  display: block;
  line-height: 30px;
}

.error-asterik-size {
  width: 7px;
}

.user-management-mobile {
  height: calc(var(--vh, 2vh) * 100);
  --border-radius: 4px;
  padding: auto;
}
.pos-modal-mobile {
  height: calc(var(--vh, 2vh) * 100);
  --border-radius: 4px;
  padding: auto;
  display: contents;
}

.form-body-mobile {
  height: calc(var(--vh, 1vh) * 100);
}

.validation-error-message {
  color: red;
  margin-left: 7px;

  padding-bottom: 5px;
}

.required-date-error{
  width: 100%;
  border: 1px solid red;
  border-radius: 5px;
  text-align: left;
}
.usermanagment-txt-box{
  min-height: 40px !important;
}
.usermanagement-loc-selct{
  --highlight-color-focused:none !important;
}