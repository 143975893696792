.form-footer {
  width: 100%;
  height: 70px;
  position: fixed;
  left: 0;
  bottom: 6%;
  z-index: 100;
  background-color: var(--ion-color-light-shade);
  text-align: center;
  border-top: 3px solid lightgray;
}

span {
  cursor: pointer;
}

.number {
  margin: 27px;
}

.minus,
.plus {
  width: 45px;
  height: 45px;
  background: #f2f2f2;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 32px;
}

input {
  height: 45px;
  width: 100px;
  text-align: center;
  font-size: 26px;
  border: 1px solid #ddd;
  display: inline-block;
  vertical-align: middle;
}

.signup-form-footer {
  height: 50px;
  background-color: var(--ion-color-light-shade);
  margin-left: -5px;
  padding-right: 15px;
  margin-right: -10px;
}

.bottom-sticky-footer {
  position: absolute;
  bottom: 0;
}

.form-footer-tablet {
  width: 100%;
  height: 80px;
  bottom: 4%;
  position: fixed;
  z-index: 100;
  background-color: var(--ion-color-light-shade);
  text-align: center;
  border-top: 3px solid lightgray;
}