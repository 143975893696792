.order-modal-pop {
    --width: 395px;
    --height: 230px;
    --border-radius: 10px;
}

.order-form-body {
    margin: 10px 20px;
    width: 360px;
}

.order-form-input {
    width: 100%;
    font-size: medium;
    border: 1px solid gray;
    border-radius: 5px;
    text-align: left;
}

.primary-header {
    margin-top: 10px;
}

.form-mobile-view {
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 23.2em;
}