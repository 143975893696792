.location-tab {
   color: #727179;
   font-size: 14px;
}

.error-alert-location {
   color: red;
   white-space: nowrap;
   margin-bottom: 2px !important;
}

.questionnaire-tab {
   color: #727179;
   font-size: 14px;
}

.question {
   display: grid;
   grid-auto-flow: row;
   grid-template-columns: repeat(3, 1fr);
   grid-template-rows: repeat(2, 1fr);
   flex-direction: row;
   flex-wrap: wrap;
   column-gap: 2%;
}

.question-map {
   margin-left: 5px;
   display: grid;
}

.location-text-input-size {
   width: 20%;
   height: 35px;
   --highlight-color-focused: none !important;
}

.location-text-input-size-mobile {
   width: 50%;
   height: 35px;
   --highlight-color-focused: none !important;
}