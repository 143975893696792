.pos-side-nav {
    .full-width-background {
        --background-color: var(--ion-color-primary);
        background-color: var(--ion-color-primary);
    }

    .total-section {

        // padding: 10px;
        .clr-blk {
            color: #181d1f;
            font-size: 14px;
            font-family: Roboto;
            font-weight: bold; 
        }
        .clr-blk-text {
            color: #181d1f;
            font-size: 13px;
            font-family: Roboto; 
        }
        .clr-blk-total {
            color: #181d1f;
            font-size: 18px;
            font-family: Roboto;
        }

        .pos-total {
            float: left;
        }

        .pos-total-value {
            float: right;
            font-weight: bold;
        }

        .pos-total-pad {
            padding: 7px;
            margin-right: -20px;
            margin-left: 5px;
        }
        .pos-total-pad-mobile {
            padding: 7px; 
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .pos-total-type-section {
        position: absolute;
        bottom: 0;
        width: -webkit-fill-available;
    } 

}

.pos-checkout-header {
    display: flex;
    width: 100%;
    margin-left: 10px;
}

.cart-products {
    width: 100%;
    overflow-y: scroll;
    overflow: auto; 
    height: calc(80vh - 25px);

    .cancel-chip-section {
        background: #92949c;
    }

    .pos-cancel-chip {
        color: #ffffff !important;
    }

    .cart-product-bg-clr {
        background: #ffffff;
    }
 

    .cart-prdt-name {
        float: left;
        font-size: 12px;
        font-family: "Roboto";
        color: black;
        word-wrap: break-word;
    } 

    .cart-no-products {
        margin-left: 11px
    }
}

.pos-checkout-ion-chip-sidenav-receipt {
    margin-top: 8px;
    width: 36px;
    left: 160px;
    background-color: #eaeaeb0a;
}

.pos-checkout-main-container {
    text-align: left;
    height: 100%;
    border-right-width: 1px;
    border-right-style: solid;
    border-right-color: #999999;

}

.pos-checkout-ion-chip-sidenav {
    margin-top: 8px;
    width: 36px;
    left: 140px;
    background-color: #eaeaeb0a;
}

.pos-checkout-items-row-border {
    border-radius: 5px;
    background-color: #ffffff; 
}

.pos-checkout-close-icon-col { 
    background-color: var(--ion-color-primary);
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    max-width: 35px;
    margin-right: 10px;
    cursor: pointer;
    
}
.pos-receipt-qty-col {   
    max-width: 25px; 
    display: flex;
    align-items: center;
    justify-content: flex-start; 
    font-size: 16px;
    margin-left: 5px;
    
}

.pos-receipt-qty-details{
    display: contents; 
}

.pos-checkout-close-icon {
    display: contents;
    color: #ffffff; 
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    max-width: 35px;
    margin-right: 10px;
}

.pos-checkout-item-names-row {
    display: flex;
    border-radius: 5px;
    border: solid 1px;
    border-color: #999999; 
    height: 30px;
    width: 85px;
}

.pos-checkout-price-col {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
}

.pos-checkout-items-col {
    align-self: center;
}

.pos-checkout-qty-col {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
}

.pos-checkout-remove-icon {
    display: flex;
    align-items: center;
    font-size: larger;
}

.pos-checkout-add-icon {
    display: flex;
    align-items: center;
    font-size: larger;
}

.pos-checkout-qty-input {
    display: flex;
    vertical-align: bottom;
}
.pos-product-qty{
    min-height: 0px !important;
    height: 0px !important;
    padding: 5px !important;
}
.self-operated-pdt-qty-input{
    height: 24px !important;
}

.pos-checkout-item-div {
    inline-size: 80px;
    overflow-wrap: break-word;
}
.pos-checkout-item-div-desk { 
    overflow-wrap: break-word;
}
.pos-receipt-item-div {
    inline-size: 100%;
    overflow-wrap: break-word;
    font-size: 13px;
}
.pos-receipt-item-div-mobile {
    inline-size: 100%;
    overflow-wrap: break-word;
    font-size: 13px;
}

.pos-checkout-total-type-section {
    bottom: 0;
    width: -webkit-fill-available;
}
.pos-checkout-line{ 
    width: 100%; 
    border-bottom: 1px solid #999999; 
    }
   
.room-modal{
        --width: 395px;
    --height: 330px;
    --border-radius: 10px;
    
  }
 
