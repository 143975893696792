.btn-full-width {
    width: 100%;
}

.payment-input {
    border: 1px solid #CCCCCE;
    border-radius: 5px;
    text-align: left;
    min-height: 48px !important;
    --highlight-color-focused: none !important;
    padding-left: 5px !important;
}

.payment-background {
    background-color: #eaeaeb;
}

.no-cards-msg-wrapper {
    color: #7c7b83;
    font-weight: bold;
    height: 100%;
    padding: 0;
    margin: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.add-card-err-msg {
    color: #7c7b83;
    font-weight: bold;
    height: 30%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.mobile-add-card-err-msg {
    color: #7c7b83;
    font-weight: bold;
    margin: 0;
}

.tablet-add-card-err-msg {
    color: #7c7b83;
    font-weight: bold;
    height: 25%;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.no-cards-msg {
    display: flex;
    width: 300px;
    margin: 5px;
    text-align: center;
    flex-direction: column;
    font-size: larger;
    line-height: 25px;
}

.credit-card-img {
    width: 300px;
    margin: 5px;
    text-align: center;
}

.payment-arrow {
    margin-left: auto;
    padding-left: 10px;
    cursor: pointer;
}

.card-details {
    padding: 0 10px;
    display: flex;
    flex-direction: column;
}

.card-img-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    margin: 3px;
}

.payment-delete-modal {
    --width: 388px;
    --height: 207px;
    --border-radius: 10px;
}

.payment-cancel {
    text-transform: capitalize;
    width: 136px;
    margin-left: 100px;
    border-radius: 5px;
    background-color: #ffffff;
    color: gray;
    font-weight: bold;
}

.confirm-delete-head {
    margin-top: 10px;
}

.ion-button-chip {
    float: right;
}

.payment-cancel-chip {
    font-size: 46px;
    width: 10px;
    color: white;
    margin-right: 20px;
}

.payment-form-delete-body {
    height: 100px;
    width: 350px;
    margin-top: 80px;
    text-align: center;
    margin-left: 35px;
}

.payment-delete-btn {
    left: 111px;
}

.delete-button {
    font-weight: bold;
    border-radius: 5px;
    width: 136px;
    text-transform: capitalize;
    background-color: #2655d9;
}

.payment-delete-header {
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #2655d9;
    color: #fff;
    z-index: 999;
    bottom: 72%;
}

.footer-payment {
    width: 100%;
    height: 55px;
    position: fixed;
    bottom: 48px;
    background-color: #eaeaeb;
    border-top: 3px solid lightgray;
}

.footer-payment-mobile {
    width: 100%;
    position: fixed;
    bottom: 55px;
}

.tablet-form-footer-payment {
    width: 100%;
    height: 65px;
    position: fixed;
    left: 0;
    bottom: 5%;
    background-color: #eaeaeb;
    border-top: 3px solid lightgray;
}

.payment-footer-col {
    display: flex;
    flex-direction: row-reverse;
}

.payment-footer-cancel-btn {
    margin-right: 0;
}


.payment-save-button {
    float: right;
    font-size: 14px;
    width: 90px;
    height: 45px;
    font-weight: bold;
    margin: 0px 1px 1px 0px;
    --border-radius: 0px;
}

.mobile-payment-save-button {
    font-size: 14px;
    width: 100%;
    height: 40px;
    font-weight: bold;
    color: #2655d9;
    --border-radius: 0px;
    margin-right: 0px;
    margin-left: 0px;
}

.tablet-payment-save-button {
    float: right;
    font-size: 14px;
    width: 90px;
    height: 50px;
    font-weight: bold;
    margin: 1px 4px 0px 0px;
    --border-radius: 0px;
}

.payment-delete-button {
    float: right;
    font-size: 14px;
    width: 90px;
    height: 45px;
    font-weight: bold;
    margin: 0px 3px 0px 0px;
    --border-radius: 0px;
}

.mobile-payment-delete-button {
    float: right;
    font-size: 14px;
    width: 90px;
    height: 50px;
    font-weight: bold;
    margin: 9px 7px 0px 0px;
    --border-radius: 0px;
}

.tablet-payment-delete-button {
    float: right;
    font-size: 14px;
    width: 90px;
    height: 50px;
    font-weight: bold;
    margin: 2px 8px 0px 0px;
    --border-radius: 0px;
}

.leaving-gaurd-cancel-button {
    color: #9a9a9f !important;
    background-color: #FFFFFF !important;
}

.vertical-center {
    margin-top: calc(1% - 11px);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

@media(min-width:1282px) {
    .vertical-center {
        margin-top: calc(1% - 8px);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}