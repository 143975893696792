.onboarding-left-section-text {
  color: #ffff;
  display: flex;
  align-items: end;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 10%;
}

.onboarding-left-section-text-mobile {
  color: #ffff;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  padding-bottom: 15%;
}

.token-exp {
  color: #000;
  display: flex;
  align-items: start;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  text-align: center;
  margin-bottom: 10px;
  padding-bottom: 1%;
  margin-left: 22px;
}

.token-exp-heading {
  font-size: 26px;
  font-weight: bold;
  line-height: 5px;
}

.new-invite-token-heading {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  margin-bottom: 10px;
}

.left-section {
  background-color: #414049 !important;
}

.font-size-for-h6 {
  font-size: 12px;
}

.zip-code-content {
  color: #ffff;
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  width: 50%;
}

.img-log {
  width: 125px;
  height: 50px;
}

.bold-h1-32 {
  font-size: 32px;
  font-weight: bold;
  line-height: 16px;
}

.theme-selected-logo {
  width: 100%;
  height: 120px;
  --background: #414049;
}

.btn-blue {
  --background: blue;
}

.heading-logo {
  font-size: 21px;
  font-weight: bolder;
  font-family: 'Roboto';
}

.heading-logo-onboard {
  font-size: 21px;
  margin-bottom: -13px;
  font-weight: bolder;
  font-family: 'Roboto';
}

.heading-mar {
  margin-top: 15%;
}

// .logo-sec {
//   margin-bottom: 37px;
// }

.color-selector {
  display: inline-block;
  border-radius: 3px;
  position: relative;
  font-family: Roboto;
  background: white;

  .hidden {
    position: absolute;
    left: 0;
    opacity: 0;
  }
}

.location-section {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 5%;
}



.loc-dropdown {
  border: 2px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  width: 320px;
}

.select-popover {
  --width: 320px;
}

.loc-label {
  border: 2px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  width: 320px;
  height: 30px;
  padding: 4px;

}

.theme-heading-mobile {
  color: white;
  font-weight: bold;
  margin-right: 15px;
  margin-left: 15;
  margin-left: 15px;
  font-size: 23px;
}

.loc-label-mobile {
  border: 2px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  width: 282px;
  height: 30px;
  padding: 4px;
}

.main-section {
  height: 100%;
}

.onboarding-market-name {
  border-color: #dbdbe0;
  border-width: thin;
  border-style: solid;
  border-radius: 5px;
  width: 30%;
  height: 45px;
}

.onboarding-market-name-mobile {
  border-color: #dbdbe0;
  border-width: thin;
  border-style: solid;
  border-radius: 5px;
  width: 95%;
  height: 45px;
}


.market-location-label {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  line-height: 1.5;
}

// .zip-code-label-span {
//   margin-left: '10px'
// }

// .zip-code-input-width {
//   width: '70%'
// }

// .logo-sec-border {
//   bottom: 70px;
// }

.logo-url-textbox {
  width: 16em;
}

.url-header-label {
  font-weight: bold;
  margin: 5px 66px;
  color: #727179;
  font-size: 14px;
}

.short-note-label {
  margin-top: 10px;
}

.url-header-label-name {
  display: none;
}

@media (min-width: 300px) and (max-width: 576px) {
  .bold-h1-32 {
    font-size: 22px;
    font-weight: bold;
    line-height: 4px;
  }

  .loc-dropdown {
    border: 2px solid rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    width: 250px;
  }

  .url-header-label {
    display: none;
  }

  .logo-url-textbox {
    width: 16em;
    margin-top: 2%;
    padding-left: 0px;
    margin-left: 0px;
  }

  .url-header-label-name {
    font-weight: bold;
    margin-top: 20px;
    margin-right: 202px;
    color: #8d8c93;
    display: block;
  }

  .heading-logo {
    font-size: 17px;
    font-weight: bolder;
    font-family: "Roboto";
  }

  .heading-mar {
    margin-top: 24%;
  }
}

@media (min-width: 576.1px) and (max-width: 768px) {
  .bold-h1-32 {
    font-size: 27px;
    font-weight: bold;
    line-height: 4px;
  }
}

.new-invite-email {
  border-color: #dbdbe0;
  border-width: thin;
  border-style: solid;
  border-radius: 5px;
  width: 70%;
  height: 45px;
}

.new-invite-section {
  display: flex;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  margin-bottom: 10px;
  margin-left: 5%;
}