.setting-form-field {
  outline: auto;
  outline-color: grey;
  text-align: justify;
  padding-left: 5px !important;
}

.settings-back-icon {
  margin-top: 8px;
}

.setting-text-input-size {
  width: 20%;
  min-height: 35px !important;
  --highlight-color-focused: none !important;
}