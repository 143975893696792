.ui-iggrid .ui-iggrid-footer, .ui-iggrid .ui-iggrid-toolbar {
    font-weight: 400;
    text-align: left;
    display: block;
    background-color: #ddd;
}
#manage-col-button{
    padding:0 !important ;
    //--padding-top: 0 !important;
    //--padding-start: 0.9em !important;
    //--padding-end: 0.9em !important;
    //--padding-bottom: 0 !important;
    //height: 2.1em !important;
    //font-size: 13px !important;
    //--border-color: var(--ion-color-primary, #3880ff);
    --background: transparent;
    //--color: var(--ion-color-primary, #3880ff);
    //background-color: var(--background-color);0987ghkgkhtfs
    #hideBtn{
        color:#896BB1FF;
    }
    .ig-data-grid-toolbar.igr-data-grid-toolbar{


    }

}

#ig-native-button-2{
    border: none !important;
}


.pager-root {
    height: 50px;
    padding: 5px 0px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width:  100%;
}
.pager-root > * {
    margin: 0px 5px;
}
.pager-text {
    width: 80px;
    text-align: center;
}

.icon-button {
    display: flex;
    user-select: none;
    cursor: pointer;
}

.icon-button-disable {
    cursor: default;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.26);
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/materialicons/v128/flUhRq6tzZclQEJ-Vdg-IuiaDsNc.woff2) format('woff2');
  }
.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: 'liga';
    -webkit-font-smoothing: antialiased;
    /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}
