/* .home-page-login{
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items:center;
    min-height:100vh;
    background-color: rgb(239, 239, 247);
}

.login-card{
    min-height:400px;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    padding:10px;
}

@media screen and (max-width:768px){
    .home-page-login{
        min-height:100vh;
    }
    .login-card{
        min-height: 100vh;
        min-width:100%;
    }
}
.login-logo{
    width:70%;
    height:60%;
    margin-bottom: 50px;
}
 */


 @media  screen and (min-width:768px) {
    .login-container{
        background-image: url("../Auth0/loginimages/landing-page.jpg");
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        min-height:100vh;
        background-size: cover;
        background-color: rgba(0,0,0, 0.6);
    }
    .landing-page-desc-container{
        width:50%;
        height:120px;
        text-align: center;
        margin-bottom: 2px;
    }
    
 }
 

.landing-page-heading{
    font-family: "Roboto";
    font-weight: 900;
    margin: 0;
    font-size: 38px;
    color: #ffffff;
}

.landing-page-desc{
    font-family: "Roboto";
    font-weight: 500;
    font-size: 23px;
    color: #ffffff;
    text-align: center;
}

.login-button{
    border-radius: 0px;
    border-radius: 6px;
    border-width:0px;
    background-color:#2828ec;
    height:48px;
    width:210px;
    font-family:roboto;
    color:cornsilk;
    font-size:20px;
    font-weight:bold;
}

@media  screen and (max-width:768px) and (max-height:700px){
    .login-container{
        background-image: url("../Auth0/loginimages/landing-page.jpg");
        min-height:100vh;
        background-size: cover;
        background-position: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: rgba(0,0,0, 0.6);
        padding:10px;
    }  
   
  .landing-page-heading{
    font-family: "Roboto";
    font-weight: 900;
    margin-top:70px;
    font-size:34px;
    color: #ffffff;
    }
    
    .landing-page-desc-container{
        width:100%;
        margin-bottom: 10px;
        padding:2px;
    }

    .landing-page-desc{
        font-family: "Roboto";
        font-weight: 500;
        font-size: 22px;
        color: #ffffff;
        text-align: center;
        margin-top: 35px;
    }

    .login-button{
        border-radius: 0px;
        border-radius: 6px;
        border-width:0px;
        background-color:#2828ec;
        height:48px;
        width:210px;
        font-family:roboto;
        color:cornsilk;
        font-size:20px;
        font-weight:bold;
        margin-top:15%;
    }
 }

 @media  screen and (max-width:768px) and (min-height:700px){
    .login-container{
        background-image: url("../Auth0/loginimages/landing-page.jpg");
        min-height:100vh;
        background-size: cover;
        display: flex;
        flex-direction: column;
        background-position: center;
        align-items: center;
        background-color: rgba(0,0,0, 0.6);
        
    }  
   
  .landing-page-heading{
    font-family: "Roboto";
    font-weight: 900;
    margin-top: 70px;
    font-size: 41px;
    color: #ffffff;
    }
    
    .landing-page-desc-container{
        width:100%;
        margin-bottom: 10px;
        padding:4px;
    }

    .landing-page-desc{
    font-family: "Roboto";
    font-weight: 500;
    font-size: 22px;
    color: #ffffff;
    text-align: center;
    margin-top: 59px;
    }

    .login-button{
        border-radius: 6px;
        border-width: 0px;
        background-color: #2828ec;
        height: 49px;
        width: 210px;
        font-family: roboto;
        color: cornsilk;
        font-size: 20px;
        font-weight: bold;
        margin-top: 14%;
    }
 }

 
/************* New css *******/
.sign-forget-password {
    border: 1px solid;
    background: #494952;
    height: 32px;
    color: #ffffff;
    border-radius: 4px;
    font-size: 15px;
}

.sign-in-btn {
    font-size: 15px;
    background-color: blue;
    border-radius: 4px;
    color: #ffffff;
    height: 35px;
    width: 100px;
}
.tnc-privacy {
    padding-left: 124px;
}
.tnc-privacy a{
    font-size: 10px;
    color: #ffffff;
}
.login-spaces{
    margin-left: 8px;
}