.global-red {
  color: red;
}

.scroll-auto-profile {
  overflow-y: scroll;
}

//new css code
#profile-new-email {
  font-weight: bold;
}

.profile-header-label {
  font-size: 23px;
}

.profile-acc-info-label {
  font-size: 17px;
}

#required-field {
  color: #74737B;
  font-weight: bold;
}

.profile-first-name-lbl {
  color: #727179;
  font-weight: bold;
  font-size: 12px;
}
 

.profile-input-first-name {
  border: 1px solid #c2c2c5;
  border-radius: 4px;
  width: 100%;
  height: 40px;
  text-overflow: ellipsis;
  min-height:40px !important;
  --highlight-color-focused: none !important;
  padding-left: 5px !important;
}

.global-red-first-name {
  color: red;
}

.global-red-last-name {
  color: red;
}

.reset-pwd-btn {
  height: 40px;
  margin-top: 2px;
}

.reset-pwd-btn-mobile {
  height: 40px;
  margin-top: 2px;
  width: 100%;
}

.profile-email-input {
  border: 1px solid #c2c2c5;
  border-radius: 4px 0px 0px 4px;
  height: 40px;
  min-height:40px !important;
  --highlight-color-focused: none !important;
  padding-left: 5px !important;
}

.profile-email-input-mobile {
  border: 1px solid #c2c2c5;
  border-radius: 4px;
  height: 40px;
  min-height:40px !important;
  --highlight-color-focused: none !important;
  padding-left: 5px !important;
}

.email-change-btn {
  height: 40px;
  --border-radius: 0px 4px 4px 0px;
  margin-left: 0px;
  margin-top: 3.14px !important;
}

.new-email {
  margin-left: -2px;
}

.confirm-email {
  margin-left: 6px;
}