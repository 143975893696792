.btm-25 {
    bottom: 25px;
}

.pl-50 {
    padding-left: 50px;
}

.consumer-list {
    .header-primary {
        font-size: 24px;
        width: 100%;
    }

    .add-consmer-right {
        text-align: end;
    }

    .add-consumer-text {
        text-transform: capitalize;
        width: 10em;
        padding-top: 4px;
        height: 40px !important;
    }

    .add-consmer-btn-lbl {
        font-size: 38px;
        font-family: bold;
        margin-right: 4px;
        transform: translate(3%, 0%);
    }

    .consmer-search-filter-lbl {
        font-size: 19px;
        font-weight: 600;
    }

    .consmer-search-result-col {
        bottom: 20px;
    }

    .consumer-select-input {
        display: flex;

        .consumer-input-item {
            width: 50%;
            height: 40px;
            min-height: 40px !important;
            right: 1px;
            border: 1px solid #C2C2C5;
            border-top-left-radius: 0%;
            border-bottom-left-radius: 0%;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            padding-left: 8px !important;
            padding-right: 8px !important;
            font-size: 14px;
        }
    }

    .consumer-select-dropdown {
        width: 50%;
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;
        color: #22212D;
        border-top-right-radius: 0%;
        border-bottom-right-radius: 0%;
        min-height: 40px !important;
        --highlight-color-focused:none !important;
    }

    .consumer-input-search {
        text-overflow: ellipsis;
        width: 60%;
        --highlight-color-focused:none !important;
    }

    .consumer-searchBy-lbl {
        color: #727179;
        font-weight: bold;
        font-size: 16px;
    }

    .status-dropdown {
        padding-left: 8px;
        padding-right: 8px;
        font-size: 14px;
        color: #22212D;
        min-height: 40px !important;
        --highlight-color-focused:none !important;
    }

    .consumer-status-col {
        .react-select-container {
            border: 1px solid rgba(0, 0, 0, 0.13);
            border-radius: 6px;
        }
    }

    .consumer-location-col {
        .react-select-container {
            border: 1px solid #C2C2C5;
            border-radius: 6px;
        }
    }

    .consumer-status-lbl {
        bottom: 30px
    }

    .grid-select-row {
        background: #2555d9;
        width: 100%;
        margin-right: 9px;
        padding-left: 7px;
        color: white !important;
        --placeholder-color: white !important;
        --placeholder-opacity: 4;
        font-weight: 600;
        min-height: 40px;
    }

    .grid-select-button {
        background: #2555d9;
        margin-right: 9px;
        padding-left: 7px;
        color: white !important;
        --placeholder-color: white !important;
        --placeholder-opacity: 4;
        font-weight: 600;
        min-width: 100px;
        min-height: 40px;
    }

    .consumer-toolbar-container {
        margin-bottom: 1px;
        margin: 5px;
    }

    .grid-container {
        height: 100%;
        margin-bottom: -2px;
    }

    .mobile-grid-container {
        height: 100%;
        margin-bottom: -20px;
    }

    .consumer-actions-container {
        position: absolute;
        align-items: center;
        right: 0;
        width: 15%;
        min-width: 100px;
        margin-bottom: 1px;
        margin: 5px;
    }

    .consumer-actions-mobile {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
    }

    .consumer-select-row-container {
        width: 15%;
    }
}

.mt56 {
    margin-top: 56px
}

.consumer-action-wider-popover {
    --width: 275px;

    p {
        border-bottom: 1px solid black;
        padding-bottom: 5px;
    }


}

.consumer-actions-select {
    color: black !important;
    --placeholder-color: black !important;
    --placeholder-opacity: 4;
    font-weight: 500;
    min-height: 40px;
    --highlight-color-focused:none !important;
}

.top-off-modal {
    --height: 330px !important;
}

.consumer-action-textbox {
    border: 1px solid #bfbfbf;
    border-radius: 4px;
}

.consumer-action-textbox-size {
    width: -webkit-fill-available;
    text-align: unset;
    min-height: 40px !important;
}

.consumer-max-shown-select {
    --width: 80px;
}

.consumer-row-container {
    height: 100%;
}

.consumer-pagination-section {
    bottom: 110px;
    margin: auto;
    width: 50%;
    height: 50px;
    padding: 5px 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .max-shown-lbl {
        padding-right: 7px;
    }

    .paginator-section {
        position: absolute;
        left: 40%;
    }

    .igniteui-paginator-section {
        margin: auto;
        width: 50%;

        .pager-root {
            border-top: none !important;
        }
    }
}

.consumer-no-pymnt-header {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    justify-content: center;
    display: flex;
}

.consumer-no-pymnt-description {
    font-size: 16px;
    font-weight: 400;
    color: #000;
    display: flex;
    text-align: center;
}

.no-payment-modal {
    --height: 245px !important;
}

.consumer-detail-header {
    color: #727179;
    font-size: 16px;
    font-weight: bold;
}

.consumer-detail-disabled {
    color: #C2C2C5;
    font-weight: 600;
}

.consumer-form-field {
    border: 1px solid #C2C2C5;
    border-radius: 4px;
    font-size: 14px;
    color: #22212D;
    min-height: 0px !important;
    --highlight-color-focused:none !important;
}

.consumer-form-disabled {
    border: 1px solid #C2C2C5;
    border-radius: 4px;
    text-align: left;
}

.form-text-input {
    height: 40px;
    min-height: 40px !important;
    padding-left: 10px !important;
}

.fingerprint-text {
    margin-top: 10px;
    font-size: 14px;
    color: #22212D;
}

.consumer-email-field {
    border: 1px solid #C2C2C5;
    border-radius: 4px;
}

.consumer-or-text {
    font-weight: 800;
    font-size: larger;
    margin-top: 2%;
    text-align: center;
}

.consumer-or-text-tablet {
    font-weight: 800;
    font-size: larger;
    margin-top: 3%;
    margin-left: 3%;
}

.consumer-plus-button {
    font-size: 15px;
    top: 14px;
    height: 34px;
}

.payment-fund {
    height: 20px;
}

.payment-fund-container {
    font-style: italic;
    font-size: 14px;
    color: #22212D;
    bottom: 20px;
    min-width: 100px;
}

.unsupported-location-text {
    font-style: italic;
    color: #22212D;
    font-size: small;
}

.consumer-footer {
    width: 100%;
    height: 70px;
    position: fixed;
    left: 0;
    bottom: 6%;
    z-index: 100;
    background-color: var(--ion-color-light-shade);
    text-align: center;
    border-top: 3px solid lightgray;
}

.active-scan-code {
    width: fit-content;
    border-radius: 4px;
    text-align: center;
    color: #22212D;
    font-size: 14px;
    background-color: #EAEAEB;
    display: flex;
    justify-content: center;
    height: 40px;
    padding: 10px;
    align-items: center;
}

.user-scan-code {
    width: fit-content;
}

.scancode-cancel-chip {
    margin-left: 10px;
    font-size: 20px;
    top: 50px;
}

.pending-email-container {
    left: 5px;
    top: 5px;
}

.pending-email-label {
    margin-top: 12px;
    color: #22212D;
    font-size: 14px;
}

.consumer-resend-button {
    align-self: flex-end;
}

.verified-email-text {
    font-weight: bold;
    color: #0EC377;
    cursor: pointer;
}

.consumer-account {
    color: #22212D;
    font-size: 14px;
}

.add-funds-button {
    align-self: center;
}

.add-funds-text {
    font-size: 14px;
    text-transform: capitalize;
}

.resend-text {
    font-size: 14px;
    text-transform: capitalize;
}

.consumer-modal-pop {
    --width: 395px;
    --height: 310px;
    --border-radius: 10px;
}

.prefix {
    position: relative;
    right: -3px;
    color: grey;
}

.scroll-content-desktop {
    overflow-y: auto;
    height: calc(100vh - 100px);
}

.autocomplete-location-dropdown {
    width: 80%;
    height: 34px;
}

.consumer-form-errors {
    color: red;
    font-size: 14px;
    font-style: italic;
}

.consumer-searchbtn-col {
    top: 15px;

    .consumer-searchbtn-container {
        width: 90px;
        height: 40px;
        text-transform: capitalize;
        float: right;
    }
}

.finger-print-section {
    margin-left: 30px;
}

.consumeer-second-row {
    width: 100%;
}

.consumer-third-row {
    column-gap: 2%;
}

.consumer-info-fields {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    column-gap: 3%;
}

.sample {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.container {
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    align-items: stretch;
    align-self: stretch;
    overflow-y: hidden;
    overflow-x: hidden;
    min-width: 200px;
    margin: 0rem;
    position: relative;
    max-width: none;
    box-sizing: border-box;
}

.grid-no-result {
    width: 100%;
    height: 100px;
    position: absolute;
    margin: -50px 0 0 -50px;
}

.form-footer-consumer {
    width: 100%;
    height: 45px;
    position: fixed;
    left: 0;
    bottom: 48px;
    z-index: 100;
    background-color: #eaeaeb;
    text-align: center;
    border-top: 3px solid lightgray;
}

.consumer-mobile-footer {
    width: 100%;
    position: fixed;
    bottom: 7%;
}

.consumer-save-button {
    float: right;
    font-size: 14px;
    width: 70px;
    height: 100%;
    font-weight: bold;
    margin: 0px 1px 1px 0px;
    --border-radius: 0px;
}

.consumer-cancel-button {
    float: right;
    font-size: 14px;
    width: 90px;
    height: 100%;
    font-weight: bold;
    margin: 0px 2px 0px 0px;
    --border-radius: 0px;
    --background: #9a9a9f;
    --color: white;
}

.spinner-consumer {
    left: 50%;
    top: 20%;
    margin-left: -4em;
}

.scan-codes-list {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    width: 100%;
    flex-flow: wrap;
    row-gap: 10px;
}

.consumer-column-btn{
    height: 41px !important;
    margin: 2px;
}

ion-select::part(icon) {
    opacity: 2.55;
    right: 10px !important;
    position: absolute !important;
}

.align-checkbox {
    text-align: center;
    margin: auto;
    padding: 3px;
}

.consumer-plus-icon {
    font-size: 36px;
    font-family: bold;
}

.scancode-add-button {
    height: 40px;
    width: 40px;
}

.consumer-filter-fields {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.min-width-group {
    min-width: 200px;
}

.min-width-100 {
    min-width: 100px;
}

.min-width-80 {
    min-width: 80px;
}

.min-width-150 {
    min-width: 150px;
}

.consumer-header-primary {
    height: 40px;
}

.pending-email-field {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.spinner-column {
    align-self: center;
}

.btn-full-width-cancel {
    width: 100%;
    --background: #9a9a9f;
    --color: white;
}

.consumer-scroll-content-mobile {
    overflow: auto;
    height: calc(100vh - 150px);
}

.consumer-detail-scroll-content {
    overflow: auto;
    height: calc(100vh - 100px);
}

.consumer-scroll-content {
    overflow: auto;
    height: calc(100vh - 50px);
}

.consumer-list-content {
    height: 100%;
    .report-bar{
        ion-select::part(icon) {
            opacity: 2.55;
            right: 10px !important;
            position: absolute !important;
            color: #ffffff !important;
        }
    }
}

.input-field-border {
    border: 1px solid #C2C2C5;
    border-radius: 4px;
    height: 40px;
}